<template>
  <modal
    :name="addOrEditModalKey"
    v-bind="localModalSettings"
    @closed="$emit('input', false)"
  >
    <div class="pt-1 col-12 text-right">
      <font-awesome-icon
        class="close-icon"
        :icon="closeIcon"
        @click="closeModal"
      />
    </div>
    <BlogContentEntry
      :content-title="contentTitle"
      :content-id="contentId"
      :content="content"
      :user-id="userId"
      :fundraising-page-id="fundraisingPageId"
      content-mode="edit"
      @close="closeModal"
    />
  </modal>
</template>
<script>
import GfContentComponents from '@gf/gf-content-components/src/GfContentComponents'

import VModal from "vue-js-modal";
import Vue from "vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const BlogContentEntry = GfContentComponents.BlogContentEntry;

Vue.use(VModal);

export default {
    components: {
        FontAwesomeIcon,
        BlogContentEntry
    },
    props: {
        contentTitle: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
        contentId: {
            type: Number,
            default: -1,
        },
        userId: {
          type: Number,
          default: 0
        },
        fundraisingPageId: {
          type: Number,
          default: 0
        },
        value: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            closeIcon: faTimes,
            localModalSettings: {
                height: "auto",
                width: "100%",
                scrollable: true,
                adaptive: true,
                delay: 100,
                maxWidth: 400
            },
            addOrEditModalKey: 'AddOrEditBlogModal',
        }
    },
    watch: {
        value(isShown){
            if(isShown) this.$modal.show(this.addOrEditModalKey);
            else this.$modal.hide(this.addOrEditModalKey);
        }
    },
    mounted(){
      window.onblogpostsubmit = () => this.$emit('submit');
    },
    methods: {
        closeModal(){
            this.$modal.hide(this.addOrEditModalKey);
        }
    }
}

</script>

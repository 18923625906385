<template lang="html">
  <div class="row">
    <span v-if="isEmpty" />
    <div
      v-if="showDistance"
      class="col"
    >
      <ActivityDigestCounter
        image-name="icon-distance.png"
        :val="distance.val"
        :measurement-unit="distance.unit"
        measurement="Distance"
      />
    </div>
    <div
      v-if="showSteps"
      class="col"
    >
      <ActivityDigestCounter
        image-name="icon-steps.png"
        :val="steps"
        measurement-unit=""
        measurement="Steps"
      />
    </div>
    <div
      v-if="showCalories"
      class="col"
    >
      <ActivityDigestCounter
        image-name="icon-calories.png"
        :val="calories.val"
        :measurement-unit="calories.unit"
        measurement="Energy"
      />
    </div>
    <div
      v-if="showTime"
      class="col"
    >
      <ActivityDigestCounter
        image-name="icon-time.png"
        :val="time.val"
        :measurement-unit="time.unit"
        measurement="Time"
      />
    </div>
  </div>
</template>

<script>
import store from "../../common/store";
import logger from "../../common/logger";
import moment from "moment";
import ActivityDigestCounter from "./ActivityDigestCounter";

export default {
    name: "ActivityDigestCounters",
    components: { ActivityDigestCounter },

    props: {
        digest: {
            Type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            state: store.state,
            allValuesEmpty: false
        };
    },

    computed: {
        isEmpty() {
            var hasValues =
                this.showDistance ||
                this.showSteps ||
                this.showCalories ||
                this.showTime;
            this.$emit("hasValues", hasValues);
            return hasValues;
        },

        distance() {
            if (this.state.regionCode == "US") {
                var valInMiles =
                    parseInt(
                        this.digest.AdditionalProperties.TotalDistanceInMeters
                    ) * 0.000621371;
                return {
                    val: (valInMiles < 1
                        ? valInMiles * 5280
                        : valInMiles
                    ).toFixed(2).toString(),
                    unit: valInMiles < 1 ? "ft" : "mi"
                };
            }
            var valInKm =
                parseInt(
                    this.digest.AdditionalProperties.TotalDistanceInMeters
                ) / 1000;
            return {
                val: (valInKm < 1 ? (valInKm * 1000).toFixed(0) : valInKm.toFixed(2)).toString(),
                unit: valInKm < 1 ? "m" : "km"
            };
        },

        showDistance() {
            return parseInt(this.distance.val) > 0;
        },

        showSteps() {
            return !(this.steps === "NA" || parseInt(this.steps) <= 0);
        },

        showCalories() {
            return !(this.calories.val === "NA" || parseInt(this.calories.val) <= 0);
        },
        showTime() {
            return !(this.time.val === "NA" || parseInt(this.time.val) <= 0);
        },

        steps() {
            if (
                isNaN(
                    parseInt(this.digest.AdditionalProperties.TotalStepInNumber)
                )
            )
                return "NA";
            return this.digest.AdditionalProperties.TotalStepInNumber;
        },

        calories() {
            if (
                isNaN(
                    parseInt(
                        this.digest.AdditionalProperties.TotalEnergyInCalories
                    )
                )
            )
                return { val: "NA", unit: "kCal" };

            var valInKiloCalories =
                this.digest.AdditionalProperties.TotalEnergyInCalories / 1000;
            return {
                val:
                    (valInKiloCalories < 1
                        ? (valInKiloCalories * 1000).toFixed(0)
                        : parseFloat(valInKiloCalories).toFixed(2)).toString(),
                unit: valInKiloCalories < 1 ? "cal" : "kCal"
            };
        },

        time() {
            if (
                isNaN(
                    parseInt(
                        this.digest.AdditionalProperties
                            .TotalActivityDurationInSeconds
                    )
                )
            )
                return { val: "NA", unit: "hrs" };

            var valInHours =
                this.digest.AdditionalProperties.TotalActivityDurationInSeconds / 3600;
            return {
                val:
                    (valInHours < 3
                        ? valInHours * 60
                        : parseFloat(valInHours)).toFixed(0).toString(),
                unit: valInHours < 3 ? "mins" : "hrs"
            };
        }
    },

    methods: {
        formattedDateTime(dateTime) {
            return moment(dateTime).format("LLL");
        }
    }
};
</script>
<style scoped></style>

<template>
  <div>
    <marquee-text
      :duration="componentSettings.duration"
      :repeat="repeatItems"
    >
      <span
        v-for="(item, i) in tickerValues"
        :key="i"
        class="donor-info"
      >        
        <span
          v-if="showIcon"
          :style="iconStyle"
        />
        <span
          class="mx-2 donation-display"
        >
          <span class="donation-name">{{ item.creator.name }}</span>
          <span class="donation-amount"> {{ item.amountText }}</span>
        </span>
      </span>
    </marquee-text>
  </div>
</template>
<script>
import constants from '../../common/services/informationDigest/constants';
import PaymentDigestMapper from '../../common/mappers/paymentDigestMapper';
import PaymentItemRetriever from './paymentItemRetriever';
import TickerBase from "./TickerBase.vue";
import store from "../../common/store";
import CurrencyHelper from '../Common/CurrencyHelper.vue';
import { currencyUtility } from "@gf/gf-utility"
import paymentTickerDemo from "../../data/paymentTickerDemo.json";

export default {
    mixins: [TickerBase, CurrencyHelper],
    props:{
          apiDomain:{
            type: String,
            default: '',
        }
    },
    data() {
        return {
            currentPage: 1,
            iconUrl:"",
            repeatItems: 1,
            store: store,
            currencySymbol: constants.DEFAULT_CURRENCY_SYMBOL,
            paymentItemRetriever: PaymentItemRetriever,
            currencyUtility: currencyUtility.default
        };
    },

    computed: {
      showIcon(){
        return this.iconUrl !== "";
      },
      iconStyle() {    
            if (this.iconUrl === "") return {};

            return {	
              'width': '30px',
              'height':'30px',
              'display':'inline-block',
              'text-align': 'center',
              'background-repeat': 'no-repeat',
              'background-size': '100%',
              'background-image': 'url(' + this.iconUrl + ')',
              'vertical-align': 'middle',
              'margin-right': '12px',
            }
        }
    },

    methods: {
        async $_TickerBase_LoadValues() {
            if (this.componentSettings.repeat)
                this.repeatItems = this.componentSettings.repeat

             if (this.componentSettings.iconUrl)
                this.iconUrl = this.componentSettings.iconUrl  

            var resCurrency = await this.currencyUtility.getCurrencyInfo(this.apiDomain, this.eventCampaignId)
            if (typeof resCurrency.symbol != 'undefined') {
              this.currencySymbol = resCurrency.symbol
            }

            this.logger.logInfo('PaymentTicker.mounted.resCurrency', resCurrency)
            
            var items = await this.retrieveItems();            
            this.mapPaymentsToTicker(items);
            this.logger.logInfo("tickerValues: ", this.tickerValues)
        },

        async retrieveItems() {
          if (this.store.state.isDemoMode) {
            return await new Promise(resolve => resolve(paymentTickerDemo));
          }
          return await this.paymentItemRetriever.retrieveItems(this.currentPage, this.fundraisingPageId, this.eventCampaignId, this.componentSettings, this.apiDomain)
        },

        mapPaymentsToTicker(items) {
            items.DigestDetail.forEach(digestDetail => {
                var paymentDigest = PaymentDigestMapper.mapInformationDigestToPaymentItem(digestDetail, false, this.componentSettings.displayDonationAmount, this.currencySymbol);
                if(paymentDigest) {
                    var totalChildrenPaymentItem = 0;
                    digestDetail.Children.forEach(childItem => {
                        if(childItem.DigestType == constants.DIGEST_TYPE_PAYMENT_ITEM){
                            paymentDigest.children.push(PaymentDigestMapper.mapInformationDigestToPaymentItem(childItem, true, this.componentSettings.displayDonationAmount, this.currencySymbol));
                            totalChildrenPaymentItem += parseFloat(childItem.AdditionalProperties.Amount || 0);
                        }
                    });
                    var total = totalChildrenPaymentItem +  parseFloat(digestDetail.AdditionalProperties.Amount || 0);
                    paymentDigest.amountText = `${this.$_CurrencyHelper_formattedFinancialFigure(total, 2, this.currencySymbol)}`

                    this.tickerValues.push(paymentDigest);
                }
            });
        }

    }
};
</script>
<style lang="css" scoped>
.donor-info {
    margin-left: 20px;
    margin-right: 20px;
}
.donation-display{
  vertical-align: middle;
}
</style>
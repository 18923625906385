<script>
import GfInformationDigestBase from '../../GfInformationDigestBase.vue';
import { logger } from "@gf/gf-logger";
import MarqueeText from 'vue-marquee-text-component';

export default {
    components: {MarqueeText},

    mixins: [GfInformationDigestBase],

    data() {
        return {
            tickerValues: [],
            logger: logger,
        };
    },

    async mounted() {
        this.$nextTick(async () => await this.$_TickerBase_AfterMounted())
        await this.$_TickerBase_LoadValues()
    },

    methods: {
        async $_TickerBase_AfterMounted() {
          // override
        },

        async $_TickerBase_LoadValues() {
          // override
        }
    }
};
</script>

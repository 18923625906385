<template>
  <modal
    :name="deleteModalKey"
    v-bind="localModalSettings"
    @closed="$emit('input', false)"
  >
    <div class="pt-1 col-12 text-right">
      <font-awesome-icon
        class="close-icon"
        :icon="closeIcon"
        @click="closeModal"
      />
    </div>
    <div>
      <p class="pt-2">
        Are you sure you want to permanently delete this post? This action cannot be undone.
      </p>
      <div class="d-flex align-items-center justify-content-end">
        <div
          class="btn text-secondary btn-cancel-post text-right"
          @click="closeModal"
        >
          Cancel
        </div>
        <div
          class="d-md-inline-block gf-driver-button btn gf-driver-button-rounded  btn-post-content text-capitalize"
          @click="deleteContent"
        >
          Delete Post
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import ContentService from "../../common/ContentService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


Vue.use(VModal);

export default {
    components: {FontAwesomeIcon},
    props: {
        contentId: {
            type: Number,
            default: -1,
        },
        userId: {
          type: Number,
          default: 0
        },
        fundraisingPageId: {
          type: Number,
          default: 0
        },
        value: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            closeIcon: faTimes,
            localModalSettings: {
                height: "auto",
                width: "100%",
                scrollable: true,
                adaptive: true,
                delay: 100,
                maxWidth: 400
            },
            deleteModalKey: 'DeleteModal',
        }
    },
    watch: {
        value(isShown){
            if(isShown) this.$modal.show(this.deleteModalKey);
            else this.$modal.hide(this.deleteModalKey);
        }
    },
    methods: {
        async deleteContent(){
			var data = {
                Id: this.contentId,
				ContentCreator: {
					UserId: this.userId,
					OnBehalfOfId: this.fundraisingPageId,
					OnBehalfOf: 'Fundraiser',
				},
				ContentPublishContext: {
					FundraisingPageId: this.fundraisingPageId,
				},
				ContentDetails: {
					Title: '',
					ContentType: "BlogPost",
					ContentTypeDetails: [],
				},
			};
            await ContentService.deleteContent(data);
            this.$emit('submit');
            this.closeModal();
        },

        closeModal(){
            this.$modal.hide(this.deleteModalKey)
        }
    }
}
</script>

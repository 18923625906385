export default {
    FUNDOMETER_CAPTIONS_DEFAULTS: {
        progressText: "RAISED SO FAR",
        showProgressText: true,
        showProgressValue: true,
        progressValue: "$0",
        targetText: "OUR GOAL",
        showTargetText: true,
        showTargetValue: true,
        targetValue: "$0"
    },

    FUNDOMETER_DEFAULTS: {
        metric: 'raised',
        type: 'bar'
    },

    FUNDOMETER_STYLE_SETTINGS_DEFAULTS: {
        height: "15px",
        borderRadius: "15px",
        trackColour: "",
        fillColour: ""
    }
}
<template>
  <div>
    <div class="row pt-2">
      <div class="col">
        <div class="row">
          <div class="col-12 h5 font-weight-bold text-right">
            {{ creator.name }}
          </div>
        </div>
        <div class="row">
          <div
            class="col text-right"
            v-html="displayText"
          />
        </div>
      </div>
      <div
        class="col-auto"
        style="min-width:50px"
      >
        <div class="m-auto justify-content-md-center">
          <profile-image
            :display-name="creator.name"
            :image-src="creator.displayImage"
            :gender="creator.gender"
            :size="50"
          />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-8 col-md-10 text-right pointer">
        <!-- empty for now -->
      </div>
      <div class="col-4 col-md-2 small text-center">
        {{ formattedDate }}
      </div>
    </div>
  </div>
</template>
<script>
import Logger from "../../common/logger";
import moment from "moment";
import ProfileImage from "../Common/ProfileImage.vue";

export default {
    name: "ContentReplyEntry",

    components: {
      ProfileImage
    },

    props: {
        displayText: {
            type: String,
            default: null
        },
        creator: {
            type: Object,
            default: () => null
        },
        createdDate: {
            type: String,
            default: ''
        }
    },

    data() {
        return {};
    },
    computed: {
        formattedDate() {
          var date = isNaN(this.createdDate) ? this.createdDate : parseInt(this.createdDate);
          return moment(date).isValid()
              ? moment(date).format("ddd Do MMMM YYYY")
              : "";
        }
    },
    mounted() {},

    methods: {}
};
</script>
<style></style>

<template lang="html">
  <div class="row">
    <div class="m-auto justify-content-center">
      <div class="row m-auto">
        <img
          class="gf-ad-counter-img"
          :src="require(`../../assets/images/${imageName}`)"
        >
        <span
          v-if="mode == '2'"
          class="pl-2"
        >
          {{ measurement }}
        </span>
      </div>
      <div class="row justify-content-center">
        <b>{{ val }} {{ measurementUnit }}</b>
      </div>
      <div
        v-if="mode == '1'"
        class="row justify-content-center"
      >
        {{ measurement }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../common/store";
import logger from "../../common/logger";
import moment from "moment";

export default {
    name: "ActivityDigestCounter",
    components: {},

    props: {
        imageName: {
            type: String,
            default: ""
        },

        val: {
            type: String,
            default: ""
        },

        measurementUnit: {
            type: String,
            default: ""
        },
        measurement: {
            type: String,
            default: ""
        },

        mode: {
          type: String,
          default: "1"
        }
    },

    data() {
        return {
            state: store.state
        };
    },

    mounted: function() {},

    methods: {
        formattedDateTime(dateTime) {
            return moment(dateTime).format("LLL");
        }
    }
};
</script>
<style scoped>
.gf-ad-counter-img {
    height: 30px;
    width: auto;
}
</style>

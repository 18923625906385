<script>
export default {
    name: "GfInformationDigestBase",

    props: {
		fundraisingPageId: {
			type: Number,
			default: 0,
		},
		eventCampaignId: {
			type: Number,
			default: 0,
		},
		beneficiaryId: {
            type: Number,
            default: 0
        },
		componentSettings: {
			type: Object,
			default: () => {},
		},
        demo: {
            type: Boolean,
            default: false,
        },
	},
}
</script>
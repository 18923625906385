import InformationDigestServiceConstants from "../../common/services/informationDigest/constants";
import currencyHelper from "../../components/Common/currencyHelperFunctions";
export default {
    mapInformationDigestToPaymentItem(digestDetail, isChildPayment, displayDonationAmount, currencySymbol) {
        if(!digestDetail || !digestDetail.Creator
            || !digestDetail.Creator.DisplayName
            || !digestDetail.AdditionalProperties.Amount)
            return null;

        var amount =  parseFloat(digestDetail.AdditionalProperties.Amount || 0);

        var hasChildrenMatched = false;

        if(digestDetail.Children && digestDetail.Children.length > 0) {
            var childrenMacthed = digestDetail.Children.find(child=> child.DigestType == InformationDigestServiceConstants.DIGEST_TYPE_PAYMENT_ITEM)
            hasChildrenMatched = childrenMacthed ? true : false;
        }



        var message = isChildPayment ? `${digestDetail.Creator.DisplayName} matched ${currencyHelper.formattedFinancialFigure(amount, 2, currencySymbol)}`
            : hasChildrenMatched ? `${digestDetail.Creator.DisplayName} <span class="hasMatching"> donated ${currencyHelper.formattedFinancialFigure(amount, 2, currencySymbol)}</span>`
                : `${digestDetail.Creator.DisplayName} donated ${currencyHelper.formattedFinancialFigure(amount, 2, currencySymbol)}`
        var paymentDigest = {
            amount: amount,
            currencySymbol: currencySymbol,
            paymentItemId: digestDetail.Id,
            createdDate: digestDetail.CreatedDateTimeOffset,
            creator: {
                name: digestDetail.Creator.DisplayName,
                displayImage: digestDetail.Creator.ProfileImage,
                gender: ""
            },
            showCreatorName: false,
            displayText: message,
            message: digestDetail.Description,
            isChildPayment: isChildPayment,
            hasChildPayment : hasChildrenMatched,
            children: [],
            replies: []
        };

        return paymentDigest;
    }
}

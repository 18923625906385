<template>
  <div>    
    <ProgressBar
      v-if="settings.type == 'bar' && ready"
      :progress-value="progressValue"
      :styling="settings.styleSettings"
      :captions="settings.captions"
    />

    <ImageFill
      v-if="settings.type == 'imageFill' && ready"
      :progress-value="progressValue"
      :styling="settings.styleSettings"
      :captions="settings.captions"
    />
  </div>
</template>
<script>
import { logger } from "@gf/gf-logger";
import defaultSettings from "../../common/defaultSettings";
import ProgressBar from "./ProgressBar";
import ImageFill from "./ImageFill";
import InformationDigestService from "../../common/InformationDigestService";
import FundometerConstants from "./fundometerConstants";
import constants from '../../common/services/informationDigest/constants';
import GfInformationDigestBaseVue from '../../GfInformationDigestBase.vue';
import metricLoader from './MetricLoader';

export default {
    name: "Fundometer",

    components: {
        ProgressBar,
        ImageFill
    },

    mixins: [GfInformationDigestBaseVue],

    data() {
        return {
            progressValue: 0,
            ready: false,
            settings: {
                type: '',
                metric: '',
                styleSettings: {},
                captions: {},
                showSubEventTotal: false,
                metricSettings:
                {
                    symbol:'',
                    shortCode: '',
                    name:''
                }
            },
            defaultSettings: defaultSettings,
            fundometerConstants: FundometerConstants,
            constants: constants
        };
    },

    created() {
        this.settings.captions = Object.assign(this.settings.captions, defaultSettings.FUNDOMETER_CAPTIONS_DEFAULTS);
        this.settings.styleSettings = Object.assign(this.settings.styleSettings, defaultSettings.FUNDOMETER_STYLE_SETTINGS_DEFAULTS);
        this.settings.type = defaultSettings.FUNDOMETER_DEFAULTS.type;
        this.settings.metric = defaultSettings.FUNDOMETER_DEFAULTS.metric;
    },

    async mounted() {
        logger.logInfo(`Fundometer.mounted(). Component Settings:`, this.componentSettings);

        if (!this.componentSettings)
            return;

        if (this.componentSettings.type && this.componentSettings.type !== '')
            this.settings.type = this.componentSettings.type;

        if (this.componentSettings.metric && this.componentSettings.metric !== '')
            this.settings.metric = this.componentSettings.metric;

        if (this.componentSettings.styleSettings)
            this.settings.styleSettings = this.componentSettings.styleSettings;

        if (this.componentSettings.metricSettingsOverride)
            this.settings.metricSettings = this.componentSettings.metricSettingsOverride;
        else {
            var resultEventCampaign = await InformationDigestService.getEventCampaignRaisedSoFar(this.eventCampaignId);
            this.settings.metricSettings.symbol = resultEventCampaign["defaults"].currency.symbol;
        }

        this.loadSettings();

        await this.loadMetrics();

        this.$nextTick(() => this.ready = true);
    },

    methods: {
        async loadMetrics() {

            var results;
            if (this.settings.metric !== '') {
                results = await this.getMetricsFromApi();
                if ((this.settings.captions.progressValue !== this.defaultSettings.FUNDOMETER_CAPTIONS_DEFAULTS.progressValue) &&
                    (this.settings.captions.targetValue !== this.defaultSettings.FUNDOMETER_CAPTIONS_DEFAULTS.targetValue))
                    results = {
                        Total: parseFloat(this.settings.captions.progressValue.replace(this.settings.metricSettings.symbol, "").replace(",", "")),
                        Target: parseFloat((this.settings.captions.targetValue).replace(this.settings.metricSettings.symbol, "").replace(",", ""))
                    }

                this.progressValue = results.Target ? parseInt((results.Total / results.Target) * 100) : 0;
                this.settings.captions.progressValue = this.settings.metricSettings.symbol + this.formattedFinancialFigure(Math.floor(results.Total), 0);
                this.settings.captions.targetValue =  results.Target? this.settings.metricSettings.symbol + this.formattedFinancialFigure(parseFloat(results.Target), 0) : this.settings.metricSettings.symbol;
                logger.logInfo("Fundometer.loadMetrics(), settings", this.settings);
            }
        },

        async getMetricsFromApi() {
            return await metricLoader.load(this.settings.metric,
                    {eid: this.eventCampaignId, bid: this.beneficiaryId, frpId: this.fundraisingPageId, showSubEventTotal: this.settings.showSubEventTotal})
        },

        loadSettings() {
            if (this.componentSettings.captions) {
                logger.logInfo("Fundometer.loadSettings(), CaptionSettings", this.componentSettings.captions);
                Object.assign(this.settings.captions, this.componentSettings.captions);
            }

            this.settings.showSubEventTotal = this.componentSettings.showSubEventTotal;
        },

        formattedFinancialFigure(val, fixed) {
            return val.toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
    }
};
</script>

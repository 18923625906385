<template>
  <div>
    <div
      class="btn-group d-flex btn-group-toggle mb-1"
      data-toggle="buttons"
    >
      <label
        :class="[
          'btn btn-block btn-light border rounded-pill mr-1',
          { active: isToday }
        ]"
      >
        <input
          type="radio"
          autocomplete="off"
          :checked="isToday"
          @click="setToday"
        >
        Today
      </label>
      <label
        :class="[
          'btn btn-block btn-light border rounded-pill',
          { active: isYesterday }
        ]"
      >
        <input
          type="radio"
          autocomplete="off"
          :checked="isYesterday"
          @click="setYesterday"
        >
        Yesterday
      </label>
    </div>
    <div class="input-group">
      <flat-pickr
        v-if="renderComponent"
        v-model="localValue"
        :config="getConfig"
        :placeholder="placeholderText"
        class="form-control"
      />
      <div class="input-group-append">
        <span
          class="input-group-text picker-icon"
          data-toggle
        >
          <i class="fa fa-calendar fa-fw" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import FormatDateHelperFunctions from "./FormatDateHelperFunctions.js";
import * as GfLocalisation from "@gf/gf-localisation";

export default {
    name: "DatePicker",
    components: {
        flatPickr
    },
    props: {
        placeholderText: {
            type: String,
            default: "Select a date"
        },
        regionCode: {
            type: String,
            default: "AU"
        },
        value: {
            type: [String, Date, Number],
            default: ""
        }
    },
    data() {
        return {
            renderComponent: false,
            config: {
                wrap: true,
                enableTime: false,
                defaultDate: null,
                type: "text",
                disableMobile: true
            },
            localValue: "",
            GfLocalisation,
            stringDtFormat: "YYYY/MM/DD"
        };
    },
    computed: {
        getConfig() {
            return this.config;
        },
        isToday() {
            return this.value === this.getDateToday();
        },
        isYesterday() {
            return this.value === this.getDateYesterday();
        }
    },

    watch: {
        value(val) {
            this.localValue = val;
        },
        localValue(val) {
            this.$emit("input", val);
        }
    },

    mounted() {
        this.config.defaultDate = new Date();
        var dtFormats = FormatDateHelperFunctions.setDateFormatByRegion(
            this.regionCode
        );
        this.config.dateFormat = dtFormats.dateTimePickerFormat;
        this.stringDtFormat = dtFormats.momentDtFormat;
        this.$nextTick(() => (this.renderComponent = true));
    },
    methods: {
        getDateToday() {
            return moment().format(this.stringDtFormat);
        },

        getDateYesterday() {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return moment(date, this.stringDtFormat).format(
                this.stringDtFormat
            );
        },

        setToday() {
            this.renderComponent = false;
            this.localValue = this.getDateToday();
            this.$nextTick(() => (this.renderComponent = true));
        },
        setYesterday() {
            const yesterday = this.getDateYesterday();
            this.renderComponent = false;
            this.localValue = yesterday;
            this.$nextTick(() => (this.renderComponent = true));
        },
        setDateFormatByRegion() {
            if (
                this.regionCode === "AU" ||
                this.regionCode === "UK" ||
                this.regionCode === "NZ"
            ) {
                this.config.dateFormat = "d/m/Y";
                this.stringDtFormat = "DD/MM/YYYY";
            } else if (this.regionCode === "US") {
                this.config.dateFormat = "m/d/Y";
                this.stringDtFormat = "MM/DD/YYYY";
            } else if (this.regionCode === "CA") {
                this.config.dateFormat = "Y-m-d";
                this.stringDtFormat = "YYYY-MM-DD";
            } else {
                this.config.dateFormat = "Y/m/d";
                this.stringDtFormat = "YYYY/MM/DD";
            }
        }
    }
};
</script>

<style scoped>
.flatpickr-input.form-control[readonly],
.flatpickr-input.form-control ~ .input-group-append .input-group-text {
    background: transparent !important;
}
.flatpickr-input.form-control {
    border-right: 0px;
}
.date-preset-btn {
    min-width: auto !important;
}
.picker-icon {
    cursor: pointer;
    border-left: 0px;
}
.btn-block + .btn-block {
    margin-top: 0;
}
</style>

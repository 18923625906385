<template lang="html">
  <div class="gf-ad-map">
    <div
      ref="grefmap"
      class="col appMap my-2"
    />
  </div>
</template>

<script>
import store from "../../common/store";
import logger from "../../common/logger";
import gmapsInit from "../../common/gmapsInit";
import Vue from "vue";

export default {
    name: "Map",
    components: {},

    props: {
        geoJson: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            state: store.state,
            gmap: null,
            mapBounds: null,
            currMap: null,
            centeredMap: false
        };
    },

    watch: {
        "state.gmap": function(val) {
            this.gmap = val;
            this.$nextTick(() => this.loadMap());
        }
    },

    created() {
        var self = this;
        window.addEventListener("message", (msg) => {
            if (msg.data && msg.data == 'centreMaps')
                setTimeout(() => self.centreMap(), 100);
        }, false);
    },

    mounted() {
        if (this.state.gmap) {
            this.gmap = this.state.gmap;
            this.$nextTick(() => this.loadMap());
        }
    },

    methods: {
        centreMap() {
            if (!this.centeredMap) {
                this.$nextTick(()=>this.currMap.fitBounds(this.mapBounds));
                this.centeredMap = true;
            }
        },

        loadMap() {
            try {
                logger.logInfo("Loading Map");
                var self = this;

                var gMapsOptions = {
                    zoom: 15,
                    zoomControl: true,
                    panControl: true,
                    draggable: true,
                    center: { lat: this.geoJson.features[0].geometry.coordinates[0][1], lng: this.geoJson.features[0].geometry.coordinates[0][0] },
                    disableDefaultUI: true,
                    scrollwheel: false
                };

                this.currMap = new this.gmap.maps.Map(
                    this.$refs.grefmap,
                    gMapsOptions
                );

                var featureStyle = {
                    strokeColor: "#3a5475",
                    strokeWeight: 4
                };

                var bounds = new this.gmap.maps.LatLngBounds();
                this.currMap.data.addListener("addfeature", function(e) {
                    logger.logInfo("adding feature");
                    logger.logInfo(bounds);
                    self.processPoints(
                        e.feature.getGeometry(),
                        bounds.extend,
                        bounds
                    );
                    self.mapBounds = bounds;           
                });

                this.currMap.data.addGeoJson(this.geoJson);
                this.currMap.data.setStyle(featureStyle);
            } catch (error) {
                logger.logInfo(`Error: ${error}`);
            }
        },

        processPoints(geometry, callback, thisArg) {
            var self = this;
            if (geometry instanceof self.gmap.maps.LatLng) {
                callback.call(thisArg, geometry);
            } else if (geometry instanceof self.gmap.maps.Data.Point) {
                callback.call(thisArg, geometry.get());
            } else {
                geometry.getArray().forEach(function(g) {
                    self.processPoints(g, callback, thisArg);
                });
            }
        }
    }
};
</script>
<style scoped>
.gf-ad-counter-img {
    height: 30px;
    width: auto;
}

.appMap {
    min-height: 300px;
}
</style>

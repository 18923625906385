import axios from "axios";
import logger from "./logger";
import fundraisingPagePaymentFeedDemo from "../data/fundraisingPagePaymentFeedDemo.json";
import Store from "./store";

export default {
    axiosAjaxOptions: {
        headers: {'x-requested-with': 'XMLHttpRequest'}
    },

    mapPaymentDigestResponse(response, displayDonationAmounts, currencyInfo) {
        var self = this;       
        return response.PaymentDigests.map(x => {
            var paymentDigest = self.mapDonationItem(x, false, x.InSupportOf, displayDonationAmounts,currencyInfo);            
            paymentDigest.amountText= `${currencyInfo.symbol}${x.HierarchyTotal}`;
            if (x.Children && x.Children.length > 0) {
                x.Children.forEach(childItem => {
                    paymentDigest.children.push(self.mapDonationItem(childItem, true, x.InSupportOf, displayDonationAmounts,currencyInfo))
                });
            }            
            return paymentDigest;
        });
    },

    mapDonationItem(x, isChildPayment, inSupportOf, displayDonationAmounts, currencyInfo) {                
        var payType = x.Type != null && x.Type.toLowerCase() =="matched" ? "Matched" : "Donated";        
        var sponsorName = `<b>${x.Name} </b> `
        var payTypePrefix = x.Type != null && x.Type.toLowerCase() =="matched" ?  sponsorName: "";
        
        var viaText = "";
        if (x.Source && x.Source !== null) {
            switch (x.Source.toLowerCase()) {
                case "wpg":
                    viaText = "via Workplace Giving ";
                    break;
                case "g2g":
                    viaText = "via Good2Give ";
                    break;
                default:
                    break;
            }
        }
       
        var inSupportOfText = isChildPayment ? "" : `in support of ${inSupportOf}`;
        var donatedAmountText = displayDonationAmounts 
            ? `<span class="small font-weight-bold" style="font-size:65%">${currencyInfo.code}&nbsp;</span>${currencyInfo.symbol}${x.Amount}</span>&nbsp;`
            : "";
        
        var message="";
        if(isChildPayment)
            message = `<span>${payTypePrefix}<p>${payType} ${donatedAmountText}</p> ${viaText}${inSupportOfText}`;        
        else
            message = `<span>${payTypePrefix}${payType} ${donatedAmountText}${viaText}${inSupportOfText}`;
        var displayImage = x.ProfileImage;
        if (isChildPayment && (displayImage == "" || displayImage == null)) {            
            displayImage = require(`../assets/images/hearts.png`)
        }

        return {
            paymentItemId: x.Id,
            createdDate: x.Created,
            creator: {
                name: x.Name,
                displayImage: displayImage,
                gender: ""
            },
            showCreatorName: x.Type == null || x.Type == "" ? true : false,
            displayText: message,
            message: x.Message,            
            children: [],
            replies: []
        };
    },

    getTimeFromEpochFromDateResponse(val) {
        return val.match(/\d+/g)[0];
    }
};

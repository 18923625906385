<template lang="html">
  <div class="gf-ad-summary col">
    <div class="row">
      <div
        v-if="totalDistance.val"
        :class="getColumnQuantity"
        class="my-2 mx-auto"
      >
        <ActivityDigestCounter
          image-name="icon-distance.png"
          :val="totalDistance.val"
          :measurement-unit="totalDistance.unit"
          measurement="Distance"
          mode="2"
        />
      </div>
      <div
        v-if="totalSteps"
        :class="getColumnQuantity"
        class="my-2 mx-auto"
      >
        <ActivityDigestCounter
          image-name="icon-steps.png"
          :val="totalSteps"
          measurement-unit=""
          measurement="Steps"
          mode="2"
        />
      </div>
      <div
        v-if="totalEnergy"
        :class="getColumnQuantity"
        class="my-2 mx-auto"
      >
        <ActivityDigestCounter
          image-name="icon-calories.png"
          :val="totalEnergy"
          measurement-unit=""
          measurement="KCal"
          mode="2"
        />
      </div>
      <div
        v-if="totalTime"
        :class="getColumnQuantity"
        class="my-2 mx-auto"
      >
        <ActivityDigestCounter
          image-name="icon-time.png"
          :val="totalTime"
          measurement-unit="hrs"
          measurement="Time"
          mode="2"
        />
      </div>
      <div
        v-if="summaryIsEmpty"
        class="col-12 text-center"
      >
        There is nothing to show at the moment.
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../common/store";
import ActivityDigestCounter from "./ActivityDigestCounter";
import { logger } from "@gf/gf-logger";

export default {
  name: "ActivityDigestSummary",
  components: { ActivityDigestCounter },

  props: {
    digestSummary: {
      type: Array,
      default: () => []
    },
    componentSettings: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      state: store.state,
      totalDistance: {},
      totalSteps: null,
      totalEnergy: null,
      totalTime: null
    };
  },

  computed: {
    summaryIsEmpty() {
      return (
        !this.totalTime &&
        !this.totalDistance.val &&
        !this.totalEnergy &&
        !this.totalTime
      );
    },
    getColumnQuantity() {
      if (
        this.componentSettings.maxColumns &&
        this.componentSettings.maxColumns > 0
      ) {
        var colwidth = 12 / this.componentSettings.maxColumns;
        logger.logInfo("colwidth");
        logger.logInfo(colwidth);
        if (colwidth >= 6) return `col-${colwidth}`;
        if (colwidth >= 3 && colwidth < 6) return `col-6 col-md-${colwidth}`;
        if (colwidth < 3) return `col-6 col-md-4 col-lg-${colwidth}`;
      }
      return "col-6";
    }
  },

  watch: {
    digestSummary(val) {
      logger.logInfo("digestSummary.watch() value:", val);
      this.processValues(val);
    }
  },

  mounted() {    
    logger.logInfo("ActivityDigestSummary.mounted(), summary details:", this.digestSummary);
    this.processValues(this.digestSummary);
  },

  methods: {
    getSummaryType(type) {
      return this.digestSummary.find(el => {
        return el.Title == type;
      });
    },

    processValues(digestSummary) {
      var distance = this.getSummaryType("TotalDistance");
      var steps = this.getSummaryType("TotalSteps");
      var energy = this.getSummaryType("TotalEnergy");
      var time = this.getSummaryType("TotalTime");

      logger.logInfo("Distance");

      if (distance)
        if (this.state.regionCode == "US")
          this.totalDistance = {
            val: (parseInt(distance.Description) * 0.000621371).toFixed(2),
            unit: "mi"
          };
        else
          this.totalDistance = {
            val: (parseInt(distance.Description) / 1000).toFixed(2),
            unit: "km"
          };

      if (steps) this.totalSteps = parseFloat(steps.Description).toFixed(0);
      if (energy)
        this.totalEnergy = (parseFloat(energy.Description) / 1000).toFixed(2);
      if (time) this.totalTime = (parseInt(time.Description) / 3600).toFixed(2);

      logger.logInfo(this.totalDistance);
    }
  }
};
</script>
<style scoped>
.gf-ad {
  background: #f8f8f8;
}
.gf-ad-profile-img {
  border-radius: 500px;
  width: 60px;
  height: 60px;
  background-color: #fff;
}

.gf-ad-time {
  width: 13px;
}

.gf-ad-counters {
  background: #f3f3f3;
}
</style>

import { logger } from "@gf/gf-logger";
import InformationDigestServiceApi from "@gf/gf-api-services/services/informationdigest/InformationDigestService";
import constants from "../../common/services/informationDigest/constants";

export default {
    async retrieveItems(
        currentPage,
        fundraisingPageId,
        eventCampaignId,
        componentSettings,
        apiDomain
    ) {
        var items = {};
        var resultsPerRequest =
            componentSettings.maxNoOfEntries !== undefined
                ? componentSettings.maxNoOfEntries
                : constants.DEFAULT_MAX_RESULTS_PER_REQUEST;

        if (fundraisingPageId && fundraisingPageId > 0)
            items = await this._getFrpPaymentDigest(
                resultsPerRequest,
                currentPage,
                apiDomain,
                fundraisingPageId,
                componentSettings.includeSubEvents
            );
        else
            items = await this._getEventPaymentDigest(
                resultsPerRequest,
                currentPage,
                apiDomain,
                eventCampaignId,
                componentSettings.includeSubEvents
            );

        return await this._getChildPayments(apiDomain, items);
    },

    async _getFrpPaymentDigest(
        resultsPerRequest,
        currentPage,
        apiDomain,
        fundraisingPageId,
        includeSubEvents
    ) {
        logger.logInfo(
            `_getFrpPaymentDigest, resultsPerRequest: ${resultsPerRequest}, currentPage: ${currentPage}, apiDomain: ${apiDomain}, fundraisingPageId: ${fundraisingPageId}, includeSubEvents: ${includeSubEvents}`
        );
        var informationDigestServiceApi = new InformationDigestServiceApi(
            apiDomain
        );
        return await informationDigestServiceApi.getPagePaymentDigest(
            fundraisingPageId,
            resultsPerRequest,
            currentPage,
            includeSubEvents
        );
    },

    async _getEventPaymentDigest(
        resultsPerRequest,
        currentPage,
        apiDomain,
        eventCampaignId,
        includeSubEvents
    ) {
        logger.logInfo(
            `_getEventPaymentDigest, resultsPerRequest: ${resultsPerRequest}, currentPage: ${currentPage}, apiDomain: ${apiDomain}, eventCampaignId: ${eventCampaignId}, includeSubEvents: ${includeSubEvents}`
        );
        var informationDigestServiceApi = new InformationDigestServiceApi(
            apiDomain
        );
        return await informationDigestServiceApi.getEventPaymentDigest(
            eventCampaignId,
            resultsPerRequest,
            currentPage,
            includeSubEvents
        );
    },

    async _getChildPayments(apiDomain, paymentDigest) {
        var requestData = paymentDigest.PaymentDigests.map((x) => {
            return {
                Id: JSON.stringify(x.Id),
                DigestType: constants.DIGEST_TYPE_PAYMENT_ITEM,
            };
        });

        return await this._getDigestDetailsById(apiDomain, requestData);
    },

    async _getDigestDetailsById(apiDomain, requestData) {
        var informationDigestServiceApi = new InformationDigestServiceApi(
            apiDomain
        );
        return await informationDigestServiceApi.retrieveDigestDetailsByIds(
            requestData,
            null,
            constants.DEFAULT_MAX_RESULTS_PER_REQUEST,
            1
        );
    },
};

import { logger } from "@gf/gf-logger";

export default {
    state: {
        addedBlogPostId: null
    },
    
    addedNewBlogPost(blogPostId) {
        this.state.addedBlogPostId = blogPostId;
        logger.logInfo(`ContentStore.addedNewBlogPost()`, blogPostId);
    }
};

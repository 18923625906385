import axios from "axios";
import logger from "./logger";
import fundraisingPageFeedDemo from "../data/fundraisingPageFeedDemo.json";
import fundraisingPageDigestSummaryDemo from "../data/fundraisingPageDigestSummaryDemo.json";
import eventCampaignDigestSummaryDemo from "../data/eventCampaignDigestSummaryDemo.json";
import fundometerDemo from "../data/fundometerDemo.json";

import Store from "./store";

export default {
    async getFundraisingPageDigest(id, take, skip) {
        if (Store.state.isDemoMode) return fundraisingPageFeedDemo;
        const data = {
            "FundraisingPageId": id,
		    "PageSize": take,
		    "PageIndex": skip
        };

        var endpoint = "/v1/informationdigest/fundraisingpagedigest";
        var res = await axios.post(
            `https://${Store.state.apiDomain}${endpoint}`,
            data
        );
        logger.logInfo("InformationDigestService.getFundraisingPageDigest(), response", res.data);

        return res.data;
    },

    async getFundraisingPageDigestSummary(id) {
        if (Store.state.isDemoMode) return fundraisingPageDigestSummaryDemo;
        const data = {
            "FundraisingPageId": id,
            "SummaryTypes": []
        };

        var endpoint = "/v1/informationdigest/fundraisingpagesummary";
        var res = await axios.post(
            `https://${Store.state.apiDomain}${endpoint}`,
            data
        );
        logger.logInfo("InformationDigestService.getFundraisingPageDigestSummary(), response", res.data);
        return res.data;
    },

    async getEventCampaignDigestSummary(id, beneficiaryId, summaryTypes) {
        logger.logInfo("InformationDigestService.getEventCampaignDigestSummary(), eventId", id);

        if (Store.state.isDemoMode) return eventCampaignDigestSummaryDemo;
        const data = {
            "EventCampaignId": id,
            "SummaryTypes": []
        };

        if (beneficiaryId)
            data.BeneficiaryId = beneficiaryId;

        if (summaryTypes)
            data.SummaryTypes = summaryTypes;

        var endpoint = "/v1/informationdigest/eventcampaignsummary";
        var res = await axios.post(
            `https://${Store.state.apiDomain}${endpoint}`,
            data
        );
        logger.logInfo("InformationDigestService.getEventCampaignDigestSummary(), response", res.data);
        return res.data;
    },

    // TODO: remove this and move it to the camapaign digest endpoint as that should return the
    // raised so far value too
    async getEventCampaignRaisedSoFar(id) {
        logger.logInfo(`InformationDigestService.getEventCampaignRaisedSoFar(), eventId: ${id}`);

        if (Store.state.isDemoMode) return fundometerDemo[JSON.stringify(id)]['Events'][0];

        var endpoint = `/v1/events/search?eventcampaignid=${id}`;
        var res = await axios.get(
            `https://${Store.state.apiDomain}${endpoint}`
        );

        return res.data['Events'][0];
    }
};

<template lang="html">
  <div id="GfFundraisingPageFeed">
    <div
      v-infinite-scroll="fetchFeedAndLoad"
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
      infinite-scroll-throttle-delay="200"
      infinite-scroll-immediate-check="false"
    >
      <div
        v-for="(digest, i) in feed"
        :key="i"        
      >        
        <ActivityDigest
          v-if="digest.DigestDetail.DigestType == 'Activity'"
          :digest="digest.DigestDetail"
          :is-page-owner="isFundraisingPageOwner"
          class="py-2"
          @onItemDeleted="showDeleteModal(digest, i)"
        />
      </div>

      <div v-if="feed.length == 0">
        There is nothing to show at the moment.
      </div>
    </div>
    <loader
      :color="spinnerColour"
      :loading="loading"
      size="36px"
    />
    <modal
      name="delete-item-modal"
      :adaptive="true"
      width="400"
      height="auto"
    >
      <div class="text-center py-3">
        <i class="fa fa-exclamation-triangle fa-fw fa-3x text-danger" />
        <p>Are you sure you want to delete this activity?</p>
        <button
          type="button"
          class="btn btn-danger"
          @click="deleteActivity()"
        >
          Yes, delete it
        </button>
        <button
          type="button"
          class="btn btn-light border ml-2"
          @click="() => $modal.hide('delete-item-modal')"
        >
          Cancel
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import store from "../common/store";
import logger from "../common/logger";
import Roles from "../common/roles";
import informationDigestService from "../common/InformationDigestService";
import ActivityDigest from "./FundraisingPage/ActivityDigest";
import Loader from "vue-spinner/src/ClipLoader.vue";
import moment from "moment";
import activityManageStore from "./ActivityEntry/activityManageStore";
import activityManagementService from "../common/ActivityManagementService";
import VModal from "vue-js-modal";

Vue.use(VModal);

export default {
	name: "FundraisingPageFeed",
	components: { Loader, ActivityDigest },
	props: {
		fundraisingPageId: {
			type: Number,
			default: 0,
		},
		userId: {
      type: Number,
      default: 0
    },
    roles: {
      type: Array,
      default: () => []
    }
	},

	data() {
		return {
			state: store.state,
			feed: [],
			spinnerColour: "#000",
			loading: true,
			resultsToPerBatch: 12,
			currentPage: 1,
			morePages: true,
			activityManageStore: activityManageStore,
			storeState: activityManageStore.state,
      isModalVisible: false,
      currentDigest: null
		};
	},

  computed: {
    isFundraisingPageOwner() {
      return (this.roles &&
          this.roles.length > 0 &&
          this.roles.find(el => el == Roles.FUNDRAISINGPAGE_OWNER)) ? true : false
    }
  },

	watch: {
		"storeState.manuallyAddedActivities": function () {
			this.addTemporaryActivity();
		},
	},

	mounted: function () {
		this.$nextTick(() => {
			this.fetchFeedAndLoad();
			// this.addTemporaryActivity();
		});
	},

	methods: {
		addTemporaryActivity() {
			this.feed.push(this.storeState.manuallyAddedActivities);
		},
		async fetchFeedAndLoad() {			
			logger.logInfo("fetchFeedAndLoad");
			if (!this.morePages) return;

			this.loading = true;

			var fundraisingPageDigestResults = await informationDigestService.getFundraisingPageDigest(
				this.fundraisingPageId,
				this.resultsToPerBatch,
				this.currentPage
			);
			logger.logInfo(fundraisingPageDigestResults);

			fundraisingPageDigestResults.FundraisingPageDigests.forEach((o) => {
				this.feed.push(o);
			});

			this.feed.concat(
				fundraisingPageDigestResults.FundraisingPageDigests
			);
			logger.logInfo(this.feed);

			this.loading = false;
			this.morePages = this.isMoreDataAvailable(
				fundraisingPageDigestResults
			);
			this.currentPage += 1;
			logger.logInfo(`current page for fetching ${this.currentPage}`);
		},

		isMoreDataAvailable(response) {
			return (
				response.ResultsReturned == this.resultsToPerBatch &&
				response.PageNumber <= response.TotalPages
			);
		},

    showDeleteModal(digest) {
       this.$modal.show('delete-item-modal');
       this.currentDigest = digest
    },

		async deleteActivity() {
      try {
        var digest = this.currentDigest
        var payload = [];
        digest.DigestDetail.UserId = this.userId;
        digest.DigestDetail.FundraisingPageId = this.fundraisingPageId;
        payload.push(digest.DigestDetail);
                    
        var result = await activityManagementService.deleteActivities(
          payload						
        );	
        if(result.success == true ){
          logger.logInfo("deleteActivity - got result", result);					
          this.feed.splice(this.feed.indexOf(digest), 1);
        }				
        else{
          logger.logInfo("deleteActivity - got error", result);
        }
      } catch (e) {
        logger.logError("deleteActivity - error", e);
      } finally {
         this.$modal.hide('delete-item-modal');
      }
    }
	},
};
</script>
<style scoped>
</style>

import axios from "axios";
import logger from "./logger";
import Store from "./store";
import publishContentDemo from "../data/publishContentDemo";
import deleteContentDemo from "../data/deleteContentDemo.json";

export default {
    axiosAjaxOptions: {
        headers: {'x-requested-with': 'XMLHttpRequest'}
    },

    async publishContent(data) {
        if (Store.state.isDemoMode) return publishContentDemo["SUCCESS"];

        var endpoint = `/content/publish`;

        var res = await axios.post(
            `${endpoint}`,
            data,
            this.axiosAjaxOptions
        );
        logger.logInfo("ContentService.publishContent(), response", res.data);
        return res.data;
    },

    async deleteContent(data) {
            if (Store.state.isDemoMode) return deleteContentDemo["SUCCESS"];

            var endpoint = `/content/delete`;

            var res = await axios.post(
                `${endpoint}`,
                data,
                this.axiosAjaxOptions
            );

            return res;

    }
};

<script>
export default {
  name: "MeasurementTypeBase",

  props: {
    measure: {
      type: String,
      required: true,
      default: "",
    },

    baseUnit: {
      type: String,
      required: true,
      default: "",
    },

    aggregateType: {
      type: String,
      required: true,
      default: "Total",
    },

    display: {
      type: String,
      required: true,
      default: "",
    },

    mode: {
      type: String,
      default: "primary",
    },

    useShortForm: {
      type: Boolean,
      default: false,
    },

    rounding: {
      type: Number,
      default: 2,
    },

    primary: {
      type: Array,
      required: true,
      default: () => [],
    },

    secondary: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      value: "",
      primaryUnit: "",
      primaryUnitShortForm: "",
      secondaryUnit: "",
      secondaryUnitShortForm: "",
      unitOption: "",
    };
  },

  computed: {
    displayUnit() {
      if (this.mode === "primary") {
        return this.useShortForm
          ? this.primary.UnitShortFormDisplay
          : this.primary.UnitDisplay;
      }
      return this.useShortForm
        ? this.secondary.UnitShortFormDisplay
        : this.secondary.UnitDisplay;
    },

    baseValue() {
      if (this.value) {
        if (this.mode === "primary") {
          const multiplier = this.primary.find(
            (uo) => uo.Value === this.unitOption
          ).BaseMultiplier;
          return parseFloat(
            (parseFloat(this.value) * multiplier).toFixed(this.rounding)
          );
        } else {
          const multiplier = this.secondary.find(
            (uo) => uo.Value === this.unitOption
          ).BaseMultiplier;
          return parseFloat(
            (parseFloat(this.value) * multiplier).toFixed(this.rounding)
          );
        }
      }
      return "";
    },

    unitOptions() {
      return this.mode === "primary" ? this.primary : this.secondary;
    },
  },

  watch: {
    value(val) {
      this.$nextTick(() => {
        this.$emit("valueUpdated", {
          Measure: this.measure,
          MeasurementUnit: this.baseUnit,
          AggregateType: this.aggregateType,
          Value: this.baseValue,
        });
      });
    },

    unitOption(val) {
      this.$nextTick(() => {
        this.$emit("valueUpdated", {
          Measure: this.measure,
          MeasurementUnit: this.baseUnit,
          AggregateType: this.aggregateType,
          Value: this.baseValue,
        })
      })
    }
  },

  mounted() {
    this.unitOption = this[this.mode].find(
      (uo) => uo.Default === true
    ).Value;
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="h2 col">
        Add New Post
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <input
          id="gf-bce-title"
          v-model="title"
          type="text"
          class="form-control border-radius-10"
          placeholder="Add a title for your post"
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ContentPost
          post-button-text="Add Post"
          :height="200"
          default-content="Hi Friends ..."
          @cancel="closeComponent()"
          @submit="postContent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ContentPost from "./ContentPost";
import ContentService from "../../common/ContentService";
import ContentStore from "../../common/contentStore";
import { logger } from "@gf/gf-logger";

export default {
    name: "BlogContentEntry",
    components: {
        ContentPost
    },

    props: {
        userId: {
            type: Number,
            default: 0
        },
        fundraisingPageId: {
          type:Number,
          default: 0
        }
    },

    data() {
      return {
        title: '',
        postBehalfOf: 'Fundraiser',
        postBehalfOfId: 0,
        contentStore: ContentStore
      }
    },

    mounted() {
        this.postBehalfOfId = this.fundraisingPageId
    },

    methods: {
        closeComponent() {
            this.$root.$emit("closeComponent");
        },

         async postContent(value) {
            logger.logInfo("Posting blog content", value);
            var data = {
                ContentCreator: {
                    UserId: this.userId,
                    OnBehalfOfId: this.postBehalfOfId,
                    OnBehalfOf: this.postBehalfOf
                },
                ContentPublishContext: {
                    FundraisingPageId: this.fundraisingPageId
                },
                ContentDetails: {
                    ContentType: "BlogPost",
                    Title: this.title,
                    Value: value.html,
                    Meta: JSON.stringify(value.json)
                }
            };
            var response = await ContentService.publishContent(data);
            if (response.success)
              this.contentStore.addedNewBlogPost(response.data.id);
            this.closeComponent();
        }
    }
};
</script>
<style scoped>
.border-radius-10 {
  border-radius: 10px !important;
}
</style>

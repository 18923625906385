<template>
  <div>
    <div
      class="gf-driver-button"
      style="display: none"
    />
    <div class="row">
      <div class="col-6 text-left heading-3">
        <span v-if="captions.showProgressText">{{
          captions.progressText
        }}</span>
      </div>
      <div class="col-6 text-right heading-3">
        <span v-if="captions.showTargetText">{{
          captions.targetText
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 text-left heading-3">
        <span v-if="captions.showProgressValue">{{
          captions.progressValue
        }}</span>
      </div>
      <div class="col-6 text-right heading-3">
        <span v-if="captions.showTargetValue">{{
          captions.targetValue
        }}</span>
      </div>
    </div>
    <div class="row mx-2 py-0">
      <div
        class="progress col-12 px-0"
        :style="{
          height: style.height,
          'background-color': style.trackColour
        }"
      >
        <div
          class="progress-done"
          :style="{
            width: progressValue + '%',
            'background-color': style.fillColour
          }"
          :data-done="progressValue"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { logger } from "@gf/gf-logger";

export default {
    name: "ProgressBar",
    props: {
        styling: {
            type: Object,
            default: () => null
        },
        captions: {
            type: Object,
            default: () => {}
        },
        progressValue: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            key: 0,
            style: {
                height: "15px",
                borderRadius: "15px",
                trackColour: "",
                fillColour: ""
            }
        };
    },
    watch: {
        styling(val) {
            this.setStyles(val);
        }
    },
    mounted() {
        if (this.styling) {
            this.setStyles(this.styling);
        }
    },

    methods: {
        setStyles(styles) {
            var driverBgColour = getComputedStyle(
                document.getElementsByClassName("gf-driver-button")[0],
                null
            ).getPropertyValue("background-color");

            if (!styles.fillColour || styles.fillColour == "")
                this.style.fillColour = driverBgColour;
            else this.style.fillColour = styles.fillColour;

            if (!styles.trackColour || styles.trackColour == "")
                this.style.trackColour = this.getRgb(this.style.fillColour)
                    .replace("rgb", "rgba")
                    .replace(")", ", 0.25)");
            else this.style.trackColour = styles.trackColour;

            if (styles.height) this.style.height = styles.height;

            if (styles.borderRadius)
                this.style.borderRadius = styles.borderRadius;

            logger.logInfo("ProgressBar.setStyles, styles: ", this.style);
        },

        getRgb(colour) {
            if (colour.startsWith("rgb")) return colour;
            if (/^#([a-f0-9]{3}){1,2}$/.test(colour)) {
                if (colour.length == 4) {
                    colour =
                        "#" +
                        [
                            colour[1],
                            colour[1],
                            colour[2],
                            colour[2],
                            colour[3],
                            colour[3]
                        ].join("");
                }
                var c = "0x" + colour.substring(1);
                return (
                    "rgb(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    ")"
                );
            }
        }
    }
};
</script>
<style scoped>
.progress {
    border-radius: 20px;
    position: relative;
    margin: 15px 0;
}

.progress-done {
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 1;
    transition: 1s ease 0.3s;
}
</style>

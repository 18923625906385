export default {
    DEFAULT_CURRENCY_SYMBOL: "$",
    DEFAULT_MAX_RESULTS_PER_REQUEST: 12,

    "DIGEST_TYPE_BLOG_POST": "BlogPost",
    "DIGEST_TYPE_COMMENT": "Comment",
    "DIGEST_TYPE_DONATIONS": "Donations",
    "DIGEST_TYPE_FUNDRAISING_PAGE": "FundraisingPage",
    "DIGEST_TYPE_PAYMENT_ITEM": "PaymentItem",

    METRICS_TYPES: {
        TotalRaised: "TotalRaised",
        TotalNumberOfDonations: "TotalNumberOfDonations"
    },

    METRICS_ADDITIONAL_PROPS: {
        RaiseTarget: "RaiseTarget"
    },

}

<template>
  <div
    v-infinite-scroll="fetchPaymentDigest"
    infinite-scroll-disabled="loading"
    infinite-scroll-distance="10"
    infinite-scroll-throttle-delay="200"
    infinite-scroll-immediate-check="false"
  >
    <PaymentDigest
      v-if="displayDonations"
      :payment-digest="paymentDigest"
      :display-reply="isFundraisingPageOwner"
      :reply-behalf-of-id="fundraisingPageId"
      reply-behalf-of="Fundraiser"
      :replyer-name="fundraisingPageTitle"
      :replyer-profile-img="fundraisingPageProfileImage"
      :user-id="userId"
      :display-donation-amount="displayDonationAmount"
    />
    <loader
      :color="spinnerColour"
      :loading="loading"
      size="36px"
    />
    <div v-if="paymentDigest.length == 0 && !loading">
      There is nothing to show at the moment.
    </div>
  </div>
</template>
<script>
import PaymentDigest from "../PaymentDigest/PaymentDigest";
import PaymentFeedService from "../../common/PaymentFeedService";
import Roles from "../../common/roles";
import InformationDigestServiceConstants from "../../common/services/informationDigest/constants";
import ContentReplyMapper from "../../common/mappers/contentReplyMapper";
import PagesService from "../../common/services/pages/PagesService";
import Loader from "vue-spinner/src/ClipLoader.vue";
import { logger } from "@gf/gf-logger";
import store from "../../common/store";
import { currencyUtility } from "@gf/gf-utility";
import InformationDigestService from "@gf/gf-api-services/services/informationdigest/InformationDigestService";
import fundraisingPagePaymentFeedDemo from "../../data/fundraisingPagePaymentFeedDemo";
import fundraisingPageServiceResponsesDemo from "../../data/fundraisingPageServiceResponsesDemo.json";
import digestDetailByIdsDemo from "../../data/digestDetailByIdsDemo.json";

export default {
  name: "FundraisingPagePaymentFeed",
  components: {
    Loader,
    PaymentDigest,
  },

  props: {
    fundraisingPageId: {
      type: Number,
      default: 0,
    },

    userId: {
      type: Number,
      default: 0,
    },

    roles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      paymentDigest: [],
      fundraisingPageTitle: "",
      fundraisingPageProfileImage: "",
      displayDonationAmount: true,
      displayDonations: true,
      spinnerColour: "#000",
      loading: true,
      resultsToPerBatch: 12,
      currentPage: 1,
      morePages: true,
      store: store,
      state: store.state,
      informationDigestService: new InformationDigestService(
        store.state.apiDomain
      ),
    };
  },

  computed: {
    isFundraisingPageOwner() {
      if (
        this.roles &&
        this.roles.length > 0 &&
        this.roles.find((el) => el == Roles.FUNDRAISINGPAGE_OWNER)
      )
        return true;

      return false;
    },
  },

  mounted: function () {
    this.populateFundraisingInfo();
    this.$nextTick(() => {
      this.fetchPaymentDigest();
    });
  },

  methods: {
    async getFundraisingPageDonations() {
      var resCurrency = (this.store.state.isDemoMode) 
        ? await new Promise(resolve => resolve(fundraisingPageServiceResponsesDemo.SearchById.default))
        : await currencyUtility.default.getCurrencyInfoByPageId(
          this.state.apiDomain,
          this.fundraisingPageId
        );
      var pageFeedResults = await this.getPagePaymentDigest();
      var mappedResponse = PaymentFeedService.mapPaymentDigestResponse(
        pageFeedResults,
        this.displayDonationAmount,
        resCurrency
      );
      return {
        donations: mappedResponse,
        pageIndex: pageFeedResults.PageNumber,
        totalPages: pageFeedResults.TotalPages,
      };
    },
    async getPagePaymentDigest() {
      if (this.store.state.isDemoMode) {
        return await new Promise(resolve => resolve(fundraisingPagePaymentFeedDemo));
      }
      return await this.informationDigestService.getPagePaymentDigest(
        this.fundraisingPageId,
        this.resultsToPerBatch,
        this.currentPage
      );
    },
    async fetchPaymentDigest() {
      if (!this.morePages) return;

      this.loading = true;
      var response = await this.getFundraisingPageDonations();
      response.donations.forEach((o) => {
        this.paymentDigest.push(o);
      });
      var totalPages = response.totalPages;
      this.morePages =
        response.donations.length == this.resultsToPerBatch &&
        response.pageIndex <= totalPages;
      var requestData = response.donations.map((x) => {
        return {
          Id: JSON.stringify(x.paymentItemId),
          DigestType:
            InformationDigestServiceConstants.DIGEST_TYPE_PAYMENT_ITEM,
        };
      });

      var res = store.state.isDemoMode 
        ? await new Promise(resolve => {
          resolve(digestDetailByIdsDemo)
        })
        : await this.informationDigestService.retrieveDigestDetailsByIds(
          requestData,
          null,
          12,
          1
        );
      res.DigestDetail.forEach((digestDetail) => {
        var paymentDigestToPushComments = this.paymentDigest.filter(
          (x) => x.paymentItemId == digestDetail.Id
        );
        if (paymentDigestToPushComments.length > 0)
          paymentDigestToPushComments = paymentDigestToPushComments[0];

        paymentDigestToPushComments.replies = digestDetail.Children.filter(
          (c) =>
            c.DigestType ==
            InformationDigestServiceConstants.DIGEST_TYPE_COMMENT
        ).map((d) => ContentReplyMapper.mapInformationDigestToContentReply(d));
      });

      this.loading = false;
      this.currentPage += 1;

      logger.logInfo(
        "FundraisingPagePaymentFeed.fetchPaymentDigest(), response after mappings: ",
        this.paymentDigest
      );
    },

    async populateFundraisingInfo() {
      var response = await PagesService.searchById(this.fundraisingPageId);
      if (response.ResultsReturned > 0) {
        this.fundraisingPageTitle =
          response.Pages[0].PageType == "T"
            ? response.Pages[0].PageTitle
            : response.Pages[0].CreatorName;
        this.fundraisingPageProfileImage = response.Pages[0].ImagePath;
        this.displayDonationAmount = response.Pages[0].DisplayDonationAmount;
        this.displayDonations = response.Pages[0].DisplayDonations;
      }
    },
  },
};
</script>

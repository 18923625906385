<template>
  <img
    :src="profileImage"
    class="gf-profile-img"
    :class="{'rounded': rounded}"
    :alt="displayName"
    :style="style"
  >
</template>

<script>
export default {
    name: "ProfileImage",
    props: {
        imageSrc: {
            type: String,
            default: null
        },
        displayName: {
            type: String,
            default: null
        },
        gender: {
            type: String,
            default: 'M'
        },
        rounded: {
            type: Boolean,
            default: true
        },
        size: {
            type: Number,
            default: 40
        }
    },

    computed: {
        profileImage() {
            if (
                !this.imageSrc ||
                this.imageSrc == ""
            ) {
                if (
                    this.gender &&
                    this.gender == "F"
                ) {
                    return "https://cdn.gofundraise.com.au/all/placeholder-images/profile-f.jpg";
                }
                return "https://cdn.gofundraise.com.au/all/placeholder-images/profile-m.jpg";
            }

            return this.imageSrc;
        },

        style() {
            if (!this.rounded)
                return { 'height': this.size + "px", 'object-fit': 'contain' }
            
            return {
                'width': this.size + "px",
                'height': this.size + "px",
            }
        }
    }
};
</script>

<style>
.gf-profile-img {
  background-color: #fff;
  object-fit: cover;
}

.gf-profile-img.rounded {
  border-radius: 500px !important;
}

</style>

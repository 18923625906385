<template>
  <div class="row">
    <div class="col-12 h5">
      {{ title }}
    </div>
    <div class="col-12 h5">
      {{ counter.value }}
    </div>
  </div>
</template>

<script>
import CounterBase from './CounterBase.vue';
import InformationDigestServiceApi from "@gf/gf-api-services/services/informationdigest/InformationDigestService"

export default {
    name: "EventCampaignPaymentCounter",
    components: {},

    mixins: [CounterBase],
    props:{
       apiDomain:{
            type: String,
            default: '',
        }
    },

    data() {
        return {
            resultsToPerBatch: 1,
            currentPage: 1
        };
    },
    methods: {
        async $_CounterBase_AfterMounted() {
            this.logger.logInfo(`$_CounterBase_AfterMounted, eventCampaignId: ${this.eventCampaignId}`);
        },

        async $_CounterBase_GetCounterValue() {
          var informationDigestServiceApi = new InformationDigestServiceApi(this.apiDomain);
            var response = await informationDigestServiceApi.getEventPaymentDigest(this.eventCampaignId, this.resultsToPerBatch, this.currentPage, this.componentSettings.IncludeSubEvents);
            this.counter.value = response.TotalPages
        }
    }
};
</script>
<style scoped>
.gf-ad-counter-img {
	height: 30px;
	width: auto;
}
</style>

export default {
    setDateFormatByRegion(regionCode) {
        var dtFormated = {
            dateTimePickerFormat: "",
            momentDtFormat: ""
        }
        if (
           regionCode === "AU" ||
           regionCode === "UK" ||
           regionCode === "NZ"
        ) { dtFormated.dateTimePickerFormat = "d/m/Y";
            dtFormated.momentDtFormat = "DD/MM/YYYY";
        } else if (regionCode === "US") {
            dtFormated.dateTimePickerFormat = "m/d/Y";
            dtFormated.momentDtFormat = "MM/DD/YYYY";
        } else if (regionCode === "CA") {
            dtFormated.dateTimePickerFormat = "Y-m-d";
            dtFormated.momentDtFormat = "YYYY-MM-DD";
        } else {
            dtFormated.dateTimePickerFormat = "Y/m/d";
            dtFormated.momentDtFormat = "YYYY/MM/DD";
        }

        return dtFormated
    }
}

<template>
  <div
    id="biggest-supporters"
    class="gf-body-text-face"
  >
    <div>
      <h3>Biggest Supporters</h3>
      <div class="row bs-details">
        <div
          v-for="item in donations.PaymentDigests"
          :key="item.Id"
          :class="[layoutClass]"
          class="bs-item topDonationItem"
        >
          <div
            v-if="isHorizontal"
            class="bs-item-wrap"
          >
            <div
              class="item-img"
            >
              <div
                class="item-img-wrap"
                :style="`background-image:url('${item.ProfileImage == ''?defaultImage:item.ProfileImage}')`"
              />
            </div>
            <div class="detail">
              <p
                v-if="componentSettings.displayDonationAmount"
                class="detail-raised primary-text-color"
              >
                <span
                 
                  class="currency-name"
                >{{ componentSettings.currencyName }}</span>  
                <span v-if="componentSettings.aggregateType === 'hierarchyTotal'">
                  {{ componentSettings.currencySymbol }}
                  {{ item.HierarchyTotal }}
                </span>
                <span v-else>
                  {{ componentSettings.currencySymbol }}
                  {{ item.Amount }}
                </span>
              </p>
              <p class="detail-name">
                {{ item.Name }}
              </p>
              <span class="heart-icon fa fa-heart primary-text-color" />
            </div>
          </div>
          <div
            v-else
            class="bs-item-wrap"
          >
            <span class="detail-name">
              {{ item.Name }}
            </span>
            <p
              v-if="componentSettings.displayDonationAmount"
              class="detail-raised detail-raised-vertical primary-text-color"
            > 
              <span class="currency-name">{{ componentSettings.currencyName }}</span> 
              <span v-if="componentSettings.aggregateType === 'hierarchyTotal'">
                {{ componentSettings.currencySymbol }}
                {{ item.HierarchyTotal }}
              </span>
              <span v-else>
                {{ componentSettings.currencySymbol }}
                {{ item.Amount }}
              </span>
            </p>
          </div>
        </div>
        <div class="clear" />
      </div>
    </div>
  </div>
</template>

<script>
import GfInformationDigestBase from "../../GfInformationDigestBase.vue";
import InformationDigestService from "@gf/gf-api-services/services/informationdigest/InformationDigestService"

export default {
    name: "FundraisingPageTopDonations",
    mixins:[GfInformationDigestBase],
    props:{
        numberOfDonations:{
            type: Number,
            default: 2,
        },
        apiDomain:{
            type: String,
            default: '',
        }
    },
    data() {
        return {
            donations: {
                type: Object,
                default: () => {},
            },
            sortBy: "total",
            sortOrder: "1",
            includeSubEvents: false,
            currentPage: 1,
            defaultImage: 'https://cdn.gofundraise.com.au/all/placeholder-images/profile-m.jpg',
            layoutClass: 'col-md-6 col-sm-6 col-xs-6', 
            isHorizontal: true
        }
    },
    mounted(){
        this.getTopDonations();

        if(!this.componentSettings.aggregateType){
          this.componentSettings.aggregateType = 'default';
        }
    },
    methods: {
        async getTopDonations() {
            this.donations = await this.getPagePaymentDigest();

            if(this.componentSettings.layout && this.componentSettings.layout === 'vertical'){
              this.layoutClass = 'col-md-12 col-sm-12 col-xs-12 border-bottom topDonationItem-vertical';
              this.isHorizontal = false;
            }
        },

        async getPagePaymentDigest(){
            var informationDigestService = new InformationDigestService(this.apiDomain);
            return await informationDigestService.getPagePaymentDigest(this.fundraisingPageId, this.numberOfDonations, this.currentPage, this.includeSubEvents, this.sortOrder, this.sortBy);
        }
    }
};
</script>
<style scoped>
span.fa-heart {
    display: none;
}

.bs-item {
    padding: 0px 15px;
    border: 2px solid #f8f8f8;
    border-top: 0;
}

.bs-item .item-img {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 50px;
    overflow: hidden;
}

.item-img-wrap {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bs-item .detail {
    display: inline-block;
    padding: 9px;
    vertical-align: middle;
    width: calc(100% - 45px);
    position: relative;
}

.bs-item .detail p.detail-raised {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0px;
}

.bs-item .detail p {
    margin: 0;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
}

span.detail-name{
  position: relative;
}

.topDonationItem span.detail-name:before {
    content: '\f155';
    font-family: 'FontAwesome' !important;
    font-weight: 400;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    padding: 5px;
    border-radius: 33px;
    font-size: 14px;
    margin-right: 10px;
    vertical-align: top;
}

.topDonationItem span.detail-name:after {
    content: '';
    width: 30px;
    height: 30px;
    display: inline-block;
    position: absolute;
    top: 0;
    left:0;
    border-radius: 50%;
    opacity: 0.15;
}

#topDonations .topDonationItem {
    font-size: 17px;
    margin-top: 20px;
    position: relative;
}

#biggest-supporters .detail-raised-vertical{
  font-weight: 700;
  margin-left: 45px;
}

.topDonationItem-vertical{
    font-size: 17px;
    margin-top: 20px;
    position: relative;
}
</style>

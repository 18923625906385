import { logger } from "@gf/gf-logger";
import constants from '../../../common/services/informationDigest/constants';
import InformationDigestService from "../../../common/InformationDigestService";

export default {
    async load(params) {
        logger.logInfo("Loading Event campaign raised metric")
        var result = await InformationDigestService.getEventCampaignDigestSummary(params.eid, params.bid, [constants.DIGEST_TYPE_DONATIONS]);
        if (result && result.DigestSummaryEntries && result.DigestSummaryEntries.length > 0 && result.DigestSummaryEntries.find(x=>x.Title == constants.METRICS_TYPES.TotalRaised))
            var valTotal = result.DigestSummaryEntries.find(x=>x.Title == constants.METRICS_TYPES.TotalRaised).Description;
            return {
                Total:  parseFloat(!valTotal || isNaN(valTotal) ? 0 : valTotal),
                Target: result.DigestSummaryEntries.find(x=>x.Title == constants.METRICS_TYPES.TotalRaised).AdditionalProperties[constants.METRICS_ADDITIONAL_PROPS.RaiseTarget]
            }
    }
}
<script>
import currencyHelper from './currencyHelperFunctions'
export default {
    methods: {
        $_CurrencyHelper_formattedFinancialFigure(val, fixed, currency) {
            return currencyHelper.formattedFinancialFigure(val, fixed, currency);
        }
    }
}
</script>

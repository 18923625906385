<template>
  <div v-if="paymentDigest.length > 0">
    <div
      v-for="(item, key) in paymentDigest"
      :key="key"
      class="row mb-3"
    >
      <div
        class="col-12 border p-2 gf-pd-entries"
        :class="{
          'border-bottom-0':
            hasReply(item) ||
            (displayReply && requestToReply(item)),
        }"
      >
        <div class="col-12">
          <PaymentDigestEntry
            :creator="item.creator"
            :display-text="item.displayText"
            :amount-text="item.amountText"
            :show-amount-text="displayDonationAmount"
            :message="item.message"
            :show-creator-name="item.showCreatorName"
            reply-button-text="Say Thanks"
            :show-reply-button="
              displayReply &&
                !requestToReply(item) &&
                !hasReply(item)
            "
            @replyClicked="onReplyRequested(item)"
          />
        </div>
        <div v-if="item.children && item.children.length > 0">
          <div
            v-for="(childItem, childKey) in item.children"
            :key="childKey"
            class="col-12"
          >
            <PaymentDigestEntry
              style="padding-left:3.5rem"
              :creator="childItem.creator"
              :display-text="childItem.displayText"
              :show-creator-name="childItem.showCreatorName"
              :show-amount-text="false"
              :show-reply-button="false"
              :profile-image-size="30"
              :profile-image-rounded="false"
              :created-date="childItem.createdDate"
            />
          </div>
        </div>
        <div class="col-12 ml-2 mt-3 small">
          {{ item.createdDate | formattedDate }}
        </div>
      </div>
      <div
        v-if="hasReply(item)"
        class="col-12 px-0"
      >
        <div
          v-for="(replyItem, replyItemKey) in item.replies"
          :key="replyItemKey"
          class="col-12 border p-2"
        >
          <ContentReplyEntry
            :display-text="replyItem.displayText"
            :creator="replyItem.creator"
            :created-date="replyItem.displayText.createdDate"
          />
        </div>
      </div>
      <div
        v-if="displayReply && requestToReply(item)"
        class="col-12 border p-2"
      >
        <div class="col-12 col-md-9 offset-md-3">
          <ContentPost
            :character-limit="1000"
            @submit="(data) => onReplySubmit(item, data)"
            @cancel="hideReply(item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import PaymentDigestEntry from "./PaymentDigestEntry";
import ContentPost from "../Content/ContentPost";
import ContentReplyEntry from "../Content/ContentReplyEntry";
import ContentService from "../../common/ContentService";
import { logger } from "@gf/gf-logger";

export default {
    name: "PaymentDigest",
    components: {
        ContentPost,
        ContentReplyEntry,
        PaymentDigestEntry
    },

    filters: {
        formattedDate(val) {
            var date = isNaN(val) ? val : parseInt(val);
            return moment(date).isValid()
                ? moment(date).format("MMMM D YYYY")
                : "";
        }
    },

    props: {
        displayReply: {
            type: Boolean,
            default: false
        },

        paymentDigest: {
            type: Array,
            default: () => []
        },

        replyBehalfOfId: {
            type: Number,
            default: 0
        },

        replyBehalfOf: {
            type: String,
            default: "Fundraiser"
        },

        replyerName: {
            type: String,
            default: ""
        },

        replyerProfileImg: {
            type: String,
            default:""
        },

        userId: {
          type: Number,
          default: 0
        },

        displayDonationAmount: {
          type: Boolean,
          default: true
        }
    },

    data() {
        return {
            requestToReplyOnIds: [],
            digests: []
        };
    },

    watch: {
      paymentDigest() {
        this.digests = [];
        Object.assign(this.paymentDigest, this.digests);
      }
    },

    mounted() {
        Object.assign(this.paymentDigest, this.digests);
    },

    methods: {
        metaContent(value) {
          const rawData = value.json;
          let newObj = [];
           const propertyTypes = ["BBCode", "Image", "Video", "Iframe"].map((itm) => {
            return itm.toLowerCase();
          });

          newObj.push({
            ContentPropertyType: "HyperText",
            Value: value.html,
            Meta: JSON.stringify(rawData),
          });

          if (rawData.content !== undefined) {
            for (const [i, item] of Object.entries(rawData.content)) {
              if (typeof item.content !== "undefined" && Array.isArray(item.content)) {
                for (let idx in item.content) {
                  if (typeof item.content[idx] != 'undefined') {
                    if(propertyTypes.includes(item.content[idx].type)) {
                      newObj.push({
                        ContentPropertyType: item.content[idx].type.charAt(0).toUpperCase() + item.content[idx].type.slice(1),
                        Meta: JSON.stringify(item.content[idx].attrs),
                        Value: item.content[idx].attrs.src
                      });
                    }
                  }
                }
              }
            }
          }

          return newObj;
        },
        async onReplySubmit(parentItem, value) {
            logger.logInfo("Reply request", { key: parentItem, value: value });

            let filteredContentTypes = this.metaContent(value);

            var data = {
              ContentCreator: {
                UserId: this.userId,
                OnBehalfOfId: this.replyBehalfOfId,
                OnBehalfOf: this.replyBehalfOf
              },
              ContentPublishContext: {
                PaymentItemId: parentItem.paymentItemId
              },
              ContentDetails: {
                ContentType: "Comment",
                Title: `Reply Comment from ${this.userId}`,
                ContentTypeDetails: filteredContentTypes,
                Value: value.html,
                Meta: JSON.stringify(value.json)
              }
            };
            var response = await ContentService.publishContent(data);
            if (!parentItem.replies)
                parentItem.replies = [];

            if (response.success) {
                parentItem.replies.push(
                {
                    createdDate: new Date(),
                    creator: {
                        name: this.replyerName,
                        displayImage: this.replyerProfileImg
                    },
                    displayText: value.html,
                    displayJson: value.json
                });
            }

            this.hideReply(parentItem);
        },

        onReplyRequested(item) {
            this.requestToReplyOnIds.push(item.paymentItemId);
        },

        hideReply(item) {
            var index = this.requestToReplyOnIds.indexOf(item.paymentItemId);
            this.requestToReplyOnIds.splice(index, 1);
        },

        hasReply(item) {
          return item.replies && item.replies.length > 0;
        },

        requestToReply(item) {
          return this.requestToReplyOnIds.indexOf(item.paymentItemId) > -1;
        },
    }
};
</script>
<style scoped>
.gf-pd-entries {
  background: #f8f8f8;
}
</style>

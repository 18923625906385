<template>
  <div>
    <div class="input-group">
      <flat-pickr
        v-if="renderComponent"
        v-model="localValue"
        :config="config"          
        :placeholder="placeholderText"
        class="form-control"
      />
      <div class="input-group-append">
        <span
          class="input-group-text picker-icon"
          data-toggle
        >
          <i class="fa fa-clock fa-clock-o fa-fw" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  name: "TimePicker",
  components: {
    flatPickr
  },
  props: {
    placeholderText: {
      type: String,
      default: "Select a time"
    },
    format: {
      type: String,
      default:  "h:i K"
    },
    value: {
      type: [String, Date, Number],
      default: ''
    }
  },
  data() {
    return {
      renderComponent: false,
      config: {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "h:i K",
        defaultDate: null,
        time_24hr: false,
        disableMobile: true,
      },
      localValue: ''
    }
  },
  watch: {
    value(val) {
      this.localValue = val
    },
    localValue(val) {
      this.$emit('input', val)
    }
  },
  mounted() {
    this.config.dateFormat = this.format
    this.$nextTick(() => this.renderComponent = true)
  }
}
</script>

<style scoped>
.flatpickr-input.form-control[readonly], 
.flatpickr-input.form-control ~ .input-group-append .input-group-text {
  background: transparent !important;
}
.flatpickr-input.form-control {
  border-right: 0px;
}
.picker-icon {
  cursor: pointer;
  border-left: 0px;
}
</style>

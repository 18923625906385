export default {
    mapInformationDigestToBlogPost(digestDetail) {
        return {
            id: digestDetail.Id,
            createdDate: digestDetail.CreatedDateTimeOffset,
            creator: {
                name:digestDetail.Creator.DisplayName,
                displayImage: digestDetail.Creator.ProfileImage,
                gender: digestDetail.Creator.AdditionalProperties.Gender
            },
            title: digestDetail.Title,
            displayText: digestDetail.AdditionalProperties.ContentItemPropertyValue,
            displayJson: digestDetail.AdditionalProperties.ContentItemPropertyMeta
        }
    }
}

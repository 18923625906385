<template lang="html">
  <div id="GfFundraisingPageSummary">
    <div>
      <ActivityDigestSummary
        :key="renderKey"
        :digest-summary="summary"
        :component-settings="componentSettings"
      />     
    </div>
  </div>
</template>

<script>
import { logger } from "@gf/gf-logger";
import ActivityDigestSummary from "./FundraisingPage/ActivityDigestSummary";
import activityManageStore from "./ActivityEntry/activityManageStore";
import GfInformationDigestBase from "../GfInformationDigestBase.vue"

export default {
	name: "ActivitySummary",
	components: { ActivityDigestSummary },
	mixins:[GfInformationDigestBase],
	data() {
		return {
			summary: [],
			spinnerColour: "#000",
			loading: true,
			morePages: true,
			activityManageStore: activityManageStore,
			storeState: activityManageStore.state,
			renderKey: 0,
		};
	},
	watch: {
		"storeState.manuallyAddedActivities": function () {				
			this.calculateAddedManuallyAcitivies();
		},
	},

	mounted () {
		logger.logInfo("ActivitySummary.mounted()");
		this.$nextTick(() => {
			this.fetchFeedAndLoad();
		});
	},

	methods: {
		logActivity() {
			var test = this.summary;
		},

		async fetchFeedAndLoad() {
			logger.logInfo("fetchFeedAndLoad ActivitySummary");

			if (!this.morePages) return;

			this.loading = true;

			var fundraisingPageDigestResults = await this.fetchFromServices();
			logger.logInfo(fundraisingPageDigestResults);

			this.summary = fundraisingPageDigestResults.DigestSummaryEntries;

			this.loading = false;
		},

		calculateAddedManuallyAcitivies() {
			this.renderKey += 1;
			logger.logInfo("ActivitySummary.calculateAddedManuallyAcitivies(), manuallyAddedActivities: ", this.storeState.manuallyAddedActivities);
			logger.logInfo("ActivitySummary.calculateAddedManuallyAcitivies(), summary: ", this.summary);
			var measurementValues = this.storeState.manuallyAddedActivities
				.DigestDetail.AdditionalProperties;

			this.pushOrChangeMeasurementTotal(
				this.summary.findIndex((s) => s.Title == "TotalDistance"),
				measurementValues.TotalDistanceInMeters
			);
			this.pushOrChangeMeasurementTotal(
				this.summary.findIndex((s) => s.Title == "TotalTime"),
				measurementValues.TotalActivityDurationInSeconds
			);
			this.pushOrChangeMeasurementTotal(
				this.summary.findIndex((s) => s.Title == "TotalSteps"),
				measurementValues.TotalStepInNumber
			);
			this.pushOrChangeMeasurementTotal(
				this.summary.findIndex((s) => s.Title == "TotalEnergy"),
				measurementValues.TotalEnergyInCalories
			);
		},

		pushOrChangeMeasurementTotal(foundIndexItem, newValue) {
			if (foundIndexItem > -1) {
				this.summary[foundIndexItem].Description = (
					parseInt(this.summary[foundIndexItem].Description) +
					parseInt(newValue ? newValue : 0)
				).toString();
			} else if (newValue) {
				var measurement = {
					DigestType: "Activity",
					Title: this.summary[foundIndexItem].Title,
					Description: newValue,
					AdditionalUris: {},
					AdditionalProperties: {},
				};
				this.summary.push(measurement);
			}
		},

		async fetchFromServices() {},
	},
};
</script>
<style scoped></style>

import Logger from "../../../common/logger";
import Store from "../../store";
import fundraisingPageSearchResponseDemo from "../../../data/fundraisingPageServiceResponsesDemo.json";
import axios from 'axios';

export default {
    async byId(id) {
        Logger.logInfo(`Search.byId(), id: ${id}`);
        if (Store.state.isDemoMode) return await new Promise(resolve => resolve(fundraisingPageSearchResponseDemo.SearchById.default));

        var endpoint = `/v1/pages/search?pageid=${id}`;
        var res = await axios.get(
            `https://${Store.state.apiDomain}${endpoint}`
        );

        Logger.logInfo("Search.byId(), response", res.data);
        return res.data;
    }
}

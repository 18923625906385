export default {
    debug: false,

    logInfo: function(msg, o) {
        if (this.debug) {
            console.log(msg);
            if (o !== undefined)
                console.log(o);
        }
    }
};

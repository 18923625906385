import axios from "axios";
import logger from "./logger";
import Store from "./store";
import activitySettingsDemo from "../data/activitySettingsDemo.json";

export default {
    axiosAjaxOptions: {
        headers: {'x-requested-with': 'XMLHttpRequest'}
    },

    async addActivities(activities) {
        logger.logInfo("Sending request to add activities:", activities);
        if (Store.state.isDemoMode) {
            await (new Promise(resolve => setTimeout(resolve, 1000)));
            return activitySettingsDemo.AddActivityResponse.data;
        }
        var endpoint = "/activity/add-activities";

        var res = await axios.post(
            `${endpoint}`,
            activities,
            this.axiosAjaxOptions
        );
        logger.logInfo("addActivities Response");
        logger.logInfo(res.data);
        return res.data;
    },

    async deleteActivities(activities) {        
        logger.logInfo("Sending request to delete activities:", activities);
        if (Store.state.isDemoMode) {
            await (new Promise(resolve => setTimeout(resolve, 1000)));
            return activitySettingsDemo.DeleteActivityResponse.data;
        }
        var endpoint = `/activity/delete-activities`;

        var res = await axios.post(
            `${endpoint}`,
            activities,
            this.axiosAjaxOptions
        );
        logger.logInfo("deleteActivities Response");
        logger.logInfo(res.data);
        return res.data;
    },

    async getActivitySettings(pageId, eventId) {
        if (Store.state.isDemoMode) return activitySettingsDemo.ActivitySettings;

        var endpoint = "/activity/get-activity-settings";

        var res = await axios.get(
            `${endpoint}?pageId=${pageId}&eventId=${eventId}`,
            this.axiosAjaxOptions
        );
        logger.logInfo("getActivitySettings Response");
        logger.logInfo(res.data);
        return res.data;
    }
};

// Apply localisation here
import * as GfLocalisation from '@gf/gf-localisation';

GfLocalisation.initLocale('informationDigest')

export default [
  { value: 'Running', text: GfLocalisation.getLocale('Running') },
  { value: 'Walking', text: GfLocalisation.getLocale('Walking') },
  { value: 'Swimming', text: GfLocalisation.getLocale('Swimming') },
  { value: 'Cycling', text: GfLocalisation.getLocale('Cycling') },
  { value: 'Steps', text: GfLocalisation.getLocale('Steps') },
  { value: 'Workout', text: GfLocalisation.getLocale('Workout') },
  { value: 'Rowing', text: GfLocalisation.getLocale('Rowing') },
  { value: 'Other', text: GfLocalisation.getLocale('Other') },
  { value: 'Reading', text: GfLocalisation.getLocale('Reading') },
  { value: 'Weights', text: GfLocalisation.getLocale('Weights') },
  { value: 'AroundTheHouse', text: GfLocalisation.getLocale('AroundTheHouse') },
  { value: 'Driving', text: GfLocalisation.getLocale('Driving') },
  { value: 'Golf', text: GfLocalisation.getLocale('Golf') },
  { value: 'Hiking', text: GfLocalisation.getLocale('Hiking') },
  { value: 'JumpingRope', text: GfLocalisation.getLocale('JumpingRope') },
  { value: 'MartialArts', text: GfLocalisation.getLocale('MartialArts') },
  { value: 'MindAndBody', text: GfLocalisation.getLocale('MindAndBody') },
  { value: 'Occupations', text: GfLocalisation.getLocale('Occupations') },
  { value: 'OutdoorActivities', text: GfLocalisation.getLocale('OutdoorActivities') },
  { value: 'Sports', text: GfLocalisation.getLocale('Sports') },
  { value: 'WaterActivities', text: GfLocalisation.getLocale('WaterActivities') },
  { value: 'WinterActivities', text: GfLocalisation.getLocale('WinterActivities') },
  { value: 'VideoGames', text: GfLocalisation.getLocale('VideoGames') },
  { value: 'PlayingMusic', text: GfLocalisation.getLocale('PlayingMusic') },
]

export default {
    formattedFinancialFigure(val, fixed, currency) {
        if (val == null)
            val = 0;

        if (typeof val === 'string')
            val = parseFloat(val)

        return `${currency}${val.toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
    }
}

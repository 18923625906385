<template>
  <div v-if="digests.length > 0">
    <div
      v-for="(item, key) in digests"
      :key="key"
      class="row mb-3"
    >
      <div
        class="col-12 border p-2 gf-bpf-entries"
        :class="{
          'border-bottom-0':
            hasReply(item) || (displayReply && requestToReply(item))
        }"
      >
        <div class="col-12">
          <ContentItem
            :creator="item.creator"
            :title="item.title"
            :display-text="item.displayText"
            text-align="justify"
            :message="item.message"
            :show-creator-name="item.showCreatorName"
            reply-button-text="Say Thanks"
            :show-reply-button="displayReply && !requestToReply(item) && !hasReply(item)"
            :is-action-permissible="isFundraisingPageOwner"
            @replyClicked="onReplyRequested(item)"
            @edit="onEditContent(item)"
            @delete="onDeleteContent(item)"
          />
        </div>

        <div class="col-12 ml-2 mt-3 small">
          {{ item.createdDate | formattedDate }}
        </div>
      </div>
      <div
        v-if="hasReply(item)"
        class="col-12 px-0"
      >
        <div
          v-for="(replyItem, replyItemKey) in item.replies"
          :key="replyItemKey"
          class="col-12 border p-2"
        >
          <ContentReplyEntry
            :display-text="replyItem.displayText"
            :creator="replyItem.creator"
            :created-date="replyItem.displayText.createdDate"
          />
        </div>
      </div>
      <div
        v-if="displayReply && requestToReply(item)"
        class="col-12 border p-2"
      >
        <div class="col-12 col-md-9 offset-md-3">
          <ContentPost
            :character-limit="1000"
            @submit="
              data => onReplySubmit(item, data)
            "
            @cancel="hideReply(item)"
          />
        </div>
      </div>
    </div>
    <ContentEdit
      v-model="isContentEditShown"
      :content="content"
      :content-title="contentTitle"
      :content-id="contentId"
      :user-id="userId"
      :fundraising-page-id="fundraisingPageId"
      @submit="loadDigests"
    />
    <ContentDelete
      v-model="isContentDeleteShown"
      :content-id="contentId"
      :user-id="userId"
      :fundraising-page-id="fundraisingPageId"
      @submit="loadDigests"
    />
  </div>
</template>
<script>
import moment from "moment";
import ContentItem from "../Content/ContentItem";
import ContentPost from "../Content/ContentPost";
import ContentReplyEntry from "../Content/ContentReplyEntry";
import ContentService from "../../common/ContentService";
import blogPostMapper from "../../common/mappers/blogPostMapper";
import InformationDigestServiceConstants from "../../common/services/informationDigest/constants";
import ContentStore from "../../common/contentStore";
import InformationDigestServiceApi from "@gf/gf-api-services/services/informationdigest/InformationDigestService"
import ContentDelete from '../Content/ContentDelete.vue';
import ContentEdit from "../Content/ContentEdit.vue";
import { logger } from "@gf/gf-logger";
import Roles from "../../common/roles";


export default {
    name: "BlogPostsFeed",
    components: {
        ContentPost,
        ContentReplyEntry,
        ContentItem,
        ContentDelete,
        ContentEdit,
    },

    filters: {
        formattedDate(val) {
            var date = isNaN(val) ? val : parseInt(val);
            return moment(date).isValid()
                ? moment(date).format("MMMM D YYYY")
                : "";
        }
    },

    props: {
        displayReply: {
            type: Boolean,
            default: false
        },

        replyBehalfOfId: {
            type: Number,
            default: 0
        },

        replyBehalfOf: {
            type: String,
            default: "Fundraiser"
        },

        replyerName: {
            type: String,
            default: ""
        },

        replyerProfileImg: {
            type: String,
            default:""
        },

        userId: {
          type: Number,
          default: 0
        },

        fundraisingPageId: {
          type: Number,
          default: 0
        },

         apiDomain:{
            type: String,
            default: '',
        },
        roles: {
          type: Array,
          default: () => []
        }
    },

    data() {
        return {
            requestToReplyOnIds: [],
            digests: [],
            contentStore: ContentStore,
            storeState: ContentStore.state,
            contentTitle: '',
            content: '',
            contentId: -1,
            isContentDeleteShown: false,
            isContentEditShown: false,
        };
    },

    computed: {
        isFundraisingPageOwner() {
            return (this.roles &&
                this.roles.length > 0 &&
                this.roles.find(el => el == Roles.FUNDRAISINGPAGE_OWNER)) ? true : false
        }
    },

    watch: {
      'storeState.addedBlogPostId': function() {
        this.loadDigests();
      }
    },

    async mounted() {
        await this.loadDigests();
    },

    methods: {
        async loadDigests() {
          this.isContentDeleteShown = false;
          this.isContentEditShown = false;
          this.digests = [];
          var id = 0;
          var digestType = null;
          if (this.fundraisingPageId) {
            id = this.fundraisingPageId;
            digestType = InformationDigestServiceConstants.DIGEST_TYPE_FUNDRAISING_PAGE;
          }

          var response = await this.getDigestByParentId(id, digestType)

          if (response && response.ResultsReturned > 0) {
            this.digests = response.DigestDetail.map(x=> blogPostMapper.mapInformationDigestToBlogPost(x));
            this.$nextTick(() => {
                $('#showLessBlog').click()
            })
          }
        },

        async getDigestByParentId(id, digestType){
           var informationDigestServiceApi = new InformationDigestServiceApi(this.apiDomain);
          return await informationDigestServiceApi.retrieveDigestDetailsByParentId(
            id, digestType, [InformationDigestServiceConstants.DIGEST_TYPE_BLOG_POST],
            100,
            1,
            null,
            true
          );
        },
        async onReplySubmit(parentItem, value) {
            logger.logInfo("Reply request", { key: parentItem, value: value });
            var data = {
              ContentCreator: {
                UserId: this.userId,
                OnBehalfOfId: this.replyBehalfOfId,
                OnBehalfOf: this.replyBehalfOf
              },
              ContentPublishContext: {
                PaymentItemId: parentItem.paymentItemId
              },
              ContentDetails: {
                ContentType: "Comment",
                Title: `Reply Comment from ${this.userId}`,
                Value: value.html,
                Meta: JSON.stringify(value.json)
              }
            };
            var response = await ContentService.publishContent(data);
            if (!parentItem.replies)
                parentItem.replies = [];

            if (response.success) {
                parentItem.replies.push(
                {
                    createdDate: new Date(),
                    creator: {
                        name: this.replyerName,
                        displayImage: this.replyerProfileImg
                    },
                    displayText: value.html,
                    displayJson: value.json
                });
            }

            this.hideReply(parentItem);
        },

        onReplyRequested(item) {
            this.requestToReplyOnIds.push(item.paymentItemId);
        },

        hideReply(item) {
            var index = this.requestToReplyOnIds.indexOf(item.paymentItemId);
            this.requestToReplyOnIds.splice(index, 1);
        },

        hasReply(item) {
          return item.replies && item.replies.length > 0;
        },

        requestToReply(item) {
          return this.requestToReplyOnIds.indexOf(item.paymentItemId) > -1;
        },

        onEditContent(item){
            this.contentTitle = item.title;
            this.content = item.displayText;
            this.contentId = +item.id;
            this.isContentEditShown = true;
        },

        onDeleteContent(item){
            this.contentId = +item.id;
            this.isContentDeleteShown = true;
        },
    }
};
</script>
<style scoped>
.gf-bpf-entries {
  background: #f8f8f8;
}
</style>

import logger from "../../common/logger";

export default {
    state: {
        isValid: true,
        currentActivity: {},
        payload: [],
        manuallyAddedActivities: {}
    },

    initialiseActivityForUser(userId, fundraisingPageId, activityData) {
        this.state.currentActivity = {
            UserId: userId,
            Name: "",
            FundraisingPageId: fundraisingPageId,
            Description: "",
            StartTime: null,
            ActivityType: { Name: "Running" },
            ActivityMeasurements: [],
            GeoJson: null,
            ...activityData
        }
        this.state.payload = [];
        this.state.isValid = true;
        logger.logInfo(`Activity Initialised`, this.state.currentActivity);
    },

    addActivity() {
        logger.logInfo("Adding activity", this.state.currentActivity)
        this.state.payload = [];
        this.state.isValid = false;
        var newActivity = {};
        Object.assign(newActivity, this.state.currentActivity)
        this.state.payload.push(newActivity);
        this.state.currentActivity = {};
        logger.logInfo(`Current Activity Payload`, this.state.payload);
    },

    setExerciseType(val) {
      this.state.currentActivity.ExerciseType = val;
      logger.logInfo(`Exercise Type set to ${val}`);
    },

    setActivityType(activityType) {
        this.state.currentActivity.ActivityType.Name = activityType;
        logger.logInfo(`Activity Type set to ${activityType}`);
    },

    setActivityName(name) {
        this.state.currentActivity.Name = name;
        logger.logInfo(`Activity Name set to ${name}`);
    },

    setActivityDescription(description) {
        this.state.currentActivity.Description = description;
        logger.logInfo(`Activity Description set to ${description}`);
    },

    setStartTime(startTime) {
        this.state.currentActivity.StartTime = startTime;
        logger.logInfo(`Start Time set to ${startTime}`);
    },

    setGeoJson(geoJson) {
        this.state.currentActivity.GeoJson = geoJson;
        logger.logInfo(`Activity GeoJson set to ${geoJson}`);
    },

    upsertMeasurement(measure, measureUnit, aggregateType, value) {
        var existingMeasurement = this.state.currentActivity.ActivityMeasurements.filter(a =>
            a.Measure == measure
            && a.AggregateType == aggregateType
            && a.MeasurementUnit == measureUnit);
        logger.logInfo("existing", existingMeasurement)
        if (existingMeasurement.length > 0) {
            if (value === '') {
              this.removeMeasurement(measure, measureUnit, aggregateType)
            } else {
              existingMeasurement[0].Value = value;
            }
        } else {
            this.state.currentActivity.ActivityMeasurements.push({
                Measure: measure,
                MeasurementUnit: measureUnit,
                AggregateType: aggregateType,
                Value: value
            });
        }

        logger.logInfo(`Current Activities:`, this.state.currentActivity);
    },

    removeMeasurement(measure, measureUnit, aggregateType) {
        logger.logInfo(`Removing Measurement: ${measure}, ${measureUnit}, ${aggregateType}`);
        var existingMeasurementIndex = this.state.currentActivity.ActivityMeasurements.findIndex(a =>
            a.Measure == measure
            && a.AggregateType == aggregateType
            && a.MeasurementUnit == measureUnit);
        logger.logInfo(`existingMeasurementIndex: ${existingMeasurementIndex}`)
        if (existingMeasurementIndex > -1)
            this.state.currentActivity.ActivityMeasurements.splice(existingMeasurementIndex, 1);
        logger.logInfo(`Current Activities:`, this.state.currentActivity);
    },

    addManualActivities(activities) {
        var addedActivity=[];
        activities.forEach((activity) => {
            addedActivity = {
                DigestDetail: {
                    DigestType: "Activity",
                    Creator: {

                        AdditionalProperties: {
                            Gender: "M"
                        }
                    },
                    CreatedDateTimeOffset: activity.StartTime,
                    SpatialData: "",
                    AdditionalUris: {},
                    AdditionalProperties: {
                        TotalDistanceInMeters: activity.ActivityMeasurements.find(m => m.Measure == "Distance") ? activity.ActivityMeasurements.find(m => m.Measure == "Distance").Value.toString() : "",
                        TotalElevationInMeters: activity.ActivityMeasurements.find(m => m.Measure == "Elevation") ? activity.ActivityMeasurements.find(m => m.Measure == "Elevation").Value.toString() : "",
                        TotalFloorsInNumber: activity.ActivityMeasurements.find(m => m.Measure == "Floors") ? activity.ActivityMeasurements.find(m => m.Measure == "Floors").Value.toString() : "",
                        TotalEnergyInCalories: activity.ActivityMeasurements.find(m => m.Measure == "Energy") ? activity.ActivityMeasurements.find(m => m.Measure == "Energy").Value.toString() : "",
                        TotalStepInNumber: activity.ActivityMeasurements.find(m => m.Measure == "Step") ? activity.ActivityMeasurements.find(m => m.Measure == "Step").Value.toString() : "",
                        TotalActivityDurationInSeconds: activity.ActivityMeasurements.find(m => m.Measure == "Time") ? activity.ActivityMeasurements.find(m => m.Measure == "Time").Value.toString() : ""
                    }
                }
            }
        });

        this.state.manuallyAddedActivities = addedActivity;
        logger.logInfo(`activityManageStore.addedManualActivity()`, addedActivity);
    }
};

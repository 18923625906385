import { logger } from "@gf/gf-logger";
import fundometerConstants from "../fundometerConstants";

export default {
    async load(apiService, params) {
        logger.logInfo("Loading fundraising page raised metric")
        var target = 0;
        var total = 0;
        var result = await apiService.searchById(params.frpId)
        if (result && result.Pages && result.Pages.length > 0) {
            if (result.Pages[0].PageType == fundometerConstants.PAGE_TYPE.Individual) {
                target = result.Pages[0].RaiseTarget;
                total = result.Pages[0].Total;
            } else if (result.Pages[0].PageType == fundometerConstants.PAGE_TYPE.Team) {
                target = result.Pages[0].Team.TeamTarget;
                total = result.Pages[0].Team.TeamTotal;
            }
        }

        return {
            Total: parseFloat(total),
            Target: parseFloat(target)
        }
    }
}
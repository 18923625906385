// Apply localisation here
import * as GfLocalisation from '@gf/gf-localisation';

GfLocalisation.initLocale('informationDigest')

export default {
    SupportedMeasurements: [{
        Type: "MeasurementInput",
        Measure: "Step",
        AggregateType: "Total",
        PlaceholderText: GfLocalisation.getLocale('steps_placeholder_text'),
        DisplayName: GfLocalisation.getLocale('steps'),
        Rounding: 0,
        Required: false,
        BaseUnit: "Number",
        ExerciseTypes: ["Running", "Walking", "Workout", "Steps", "Other"],
        RequiredExerciseTypes: ["Steps"],
        ModeForRegion: {
            AU: "primary",
            NZ: "primary",
            US: "primary"
        },
        Primary: [{
            Default: true,
            Value: "steps",
            UnitDisplay: GfLocalisation.getLocale('steps'),
            UnitShortFormDisplay: GfLocalisation.getLocale('steps_short'),
            BaseMultiplier: 1
        }],
        Secondary: [{
            Default: true,
            Value: "steps",
            UnitDisplay: GfLocalisation.getLocale('steps'),
            UnitShortFormDisplay: GfLocalisation.getLocale('steps_short'),
            BaseMultiplier: 1
        }]
    }, {
        Type: "MeasurementInput",
        Measure: "Pedals",
        AggregateType: "Total",
        PlaceholderText: GfLocalisation.getLocale('pedals_placeholder_text'),
        DisplayName: GfLocalisation.getLocale('pedals'),
        Rounding: 0,
        Required: false,
        BaseUnit: "Number",
        ExerciseTypes: ["Cycling"],
        RequiredExerciseTypes: [],
        ModeForRegion: {
            AU: "primary",
            NZ: "primary",
            US: "primary"
        },
        Primary: [{
            Default: true,
            Value: "pedals",
            UnitDisplay: GfLocalisation.getLocale('pedals'),
            UnitShortFormDisplay: GfLocalisation.getLocale('pedals_short'),
            BaseMultiplier: 1
        }],
        Secondary: [{
            Default: true,
            Value: "pedals",
            UnitDisplay: GfLocalisation.getLocale('pedals'),
            UnitShortFormDisplay: GfLocalisation.getLocale('pedals_short'),
            BaseMultiplier: 1
        }]
    }, {
        Type: "MeasurementInput",
        Measure: "Distance",
        AggregateType: "Total",
        PlaceholderText: GfLocalisation.getLocale('distance_placeholder_text'),
        DisplayName: GfLocalisation.getLocale('distance'),
        Rounding: 6,
        Required: false,
        BaseUnit: "Meters",
        ExerciseTypes: [],
        RequiredExerciseTypes: [],
        ModeForRegion: {
            AU: "primary",
            NZ: "primary",
            US: "secondary",
            UK: "secondary"
        },
        Primary: [{
            Default: true,
            Value: "km",
            UnitDisplay: GfLocalisation.getLocale('kilometers'),
            UnitShortFormDisplay: GfLocalisation.getLocale('kilometers_short'),
            BaseMultiplier: 1000
        }, {
            Default: false,
            Value: "m",
            UnitDisplay: GfLocalisation.getLocale('meters'),
            UnitShortFormDisplay: GfLocalisation.getLocale('meters_short'),
            BaseMultiplier: 1
        }],
        Secondary: [{
            Default: true,
            Value: "mi",
            UnitDisplay: GfLocalisation.getLocale('miles'),
            UnitShortFormDisplay: GfLocalisation.getLocale('miles_short'),
            BaseMultiplier: 1609.34
        }, {
            Default: false,
            Value: "ft",
            UnitDisplay: GfLocalisation.getLocale('feet'),
            UnitShortFormDisplay: GfLocalisation.getLocale('feet_short'),
            BaseMultiplier: 0.3048
        }]
    }, {
        Type: "MeasurementInput",
        Measure: "Time",
        AggregateType: "Total",
        PlaceholderText: GfLocalisation.getLocale('duration_placeholder_text'),
        DisplayName: GfLocalisation.getLocale('duration'),
        Rounding: 6,
        Required: true,
        BaseUnit: "Seconds",
        ExerciseTypes: [],
        RequiredExerciseTypes: [],
        ModeForRegion: {
            AU: "primary",
            NZ: "primary",
            US: "primary"
        },
        Primary: [{
            Default: true,
            Value: "min",
            UnitDisplay: GfLocalisation.getLocale('minutes'),
            UnitShortFormDisplay: GfLocalisation.getLocale('minutes_short'),
            BaseMultiplier: 60
        }, {
            Default: false,
            Value: "hr",
            UnitDisplay: GfLocalisation.getLocale('hours'),
            UnitShortFormDisplay: GfLocalisation.getLocale('hours_short'),
            BaseMultiplier: 3600
        }],
        Secondary: [{
            Default: true,
            Value: "min",
            UnitDisplay: GfLocalisation.getLocale('minutes'),
            UnitShortFormDisplay: GfLocalisation.getLocale('minutes_short'),
            BaseMultiplier: 60
        }, {
            Default: false,
            Value: "hr",
            UnitDisplay: GfLocalisation.getLocale('hours'),
            UnitShortFormDisplay: GfLocalisation.getLocale('minutes_short'),
            BaseMultiplier: 3600
        }]
    }]
}
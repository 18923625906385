export default {
    METRIC_TYPE: {
        FundraisingPageRaised: "frpraised",
        Raised: "raised",
        EventCampaignBeneficiaryRaised: "ecbraised"
    },
    PAGE_TYPE: {
        Individual: "S",
        Team: "T"
    }
}

import fundometerConstants from './fundometerConstants'
import frpRaisedMetricLoader from './MetricLoaders/fundraisingPageRaised'
import eventBeneficiaryRaisedMetricLoader from './MetricLoaders/eventCampaignBeneficiaryRaised'
import eventCampaignRaisedMetricLoader from './MetricLoaders/eventCampaignRaisedMetricLoader'
import { logger } from "@gf/gf-logger";
import Store from "../../common/store";

import PagesServiceApi from "@gf/gf-api-services/services/pages/PagesService"

export default {
    async load(type, params) {
        logger.logInfo("loading metric type with params", { type: type, params: params });
        if (type == fundometerConstants.METRIC_TYPE.FundraisingPageRaised){
            var pagesService = new PagesServiceApi(Store.state.apiDomain)
            return await frpRaisedMetricLoader.load(pagesService, params)
        }
        if (type == fundometerConstants.METRIC_TYPE.Raised)
            return await eventCampaignRaisedMetricLoader.load(params)

        if (type == fundometerConstants.METRIC_TYPE.EventCampaignBeneficiaryRaised)
            return await eventBeneficiaryRaisedMetricLoader.load(params)

        return {
            Total: 0,
            Target: 0
        }
    }
}
<template>
  <div>
    <div class="input-group">
      <input
        v-model="value"
        type="text"
        :placeholder="display"
        class="form-control"
      >
      <div
        v-if="unitOptions.length > 1"
        class="input-group-append"
      >
        <select
          v-model="unitOption"
          class="form-control"
        >
          <option
            v-for="(option, unitOptionKey) in unitOptions"
            :key="unitOptionKey"
            :value="option.Value"
          >
            {{ option.UnitShortFormDisplay }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import MeasurementTypeBase from "./MeasurementTypeBase"

export default {
    name: "MeasurementInput",
    mixins: [MeasurementTypeBase],

    mounted() {
    },

    methods: {
    }
}
</script>
<style scoped>
.measurement-unit-append {
    min-width: 100px;
}

.input-group-append {
  min-width: 80px;
}

.input-group select.form-control {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
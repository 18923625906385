import { logger } from "@gf/gf-logger";

export default {
    state: {
        isDebug: false,
        isDemoMode: false,
        apiDomain: "api.gofundraise.com",
        componentMode: null,
        componentSettings: null,
        eventId: null,
        fundraisingPageId: null,
        regionCode: "AU",
        gmap: null,
        roles: []
    },

    setToDebugMode() {
        this.state.isDebug = true;
    },

    setComponentMode(mode, settings) {
        this.state.componentMode = mode;
        if (settings && settings !== {})
            this.state.componentSettings = settings;
        logger.logInfo(`Setting mode to ${mode}, with settings:`, settings);
    },

    disableDemoMode() {
        this.state.isDemoMode = false;
        logger.logInfo("Store.disableDemoMode(): disabling demo mode");
    },


    enableDemoMode() {
        this.state.isDemoMode = true;
        logger.logInfo("Store.enableDemoMode(): enabling demo mode");
    },

    setEventId(eventId) {
        this.state.eventId = eventId;
        logger.logInfo(`Store.setEventId: ${eventId}`);
    },

    setFundraisingPageId(frpId) {
        this.state.fundraisingPageId = frpId;
        logger.logInfo(`Store.setFundraisingPageId(): ${frpId}`);
    },

    setRegionCode(code) {
        this.state.regionCode = code;
        logger.logInfo(`Store.setRegionCode(): ${code}`);
    },

    setApiDomain(apiDomain) {
        this.state.apiDomain = apiDomain;
        logger.logInfo(`Store.setApiDomain(): ${apiDomain}`);
    },

    setMapsAsInitialised(map) {
        this.state.gmap = map;
        logger.logInfo(`Store.setMapsAsInitialised(): maps initialised`);
    },

    setRoles(roles) {
        this.state.roles = roles;
        logger.logInfo(`Store.setRoles()`, roles);
    }
};

<template>
  <div>
    <div
      class="gf-driver-button"
      style="display: none"
    />

    <div
      class="fundometer-fill-wrap"
      :style="{           
        'max-width': style.fillMaxWidth,                            
      }"
    >
      <div 
        class="fundometer-fill"     
      >      
        <div class="fill-image">
          <img :src="style.imageUrl">                               
        </div>
      
        <div class="color-fill-container">
          <div class="color-fill-wrap">
            <div 
              :class="[
                'color-fill fill-progress',
                'df-' + style.direction,
                { loaded: isLoaded }
              ]"
              :style="{
                width: style.width + '%',    
                height: style.height + '%',              
                'background-color': style.fillColour
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { logger } from "@gf/gf-logger";

export default {
    name: "ImageFill",
    props: {
        styling: {
            type: Object,
            default: () => null
        },
        captions: {
            type: Object,
            default: () => {}
        },
        progressValue: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {            
            isLoaded: false,
            style: {                                      
                fillColour: "",
                imageUrl: "",
                direction: "",
                width: 0,
                height: 0,                
                fillMaxWidth:'300px'
            }
        };
    },
    watch: {
        styling(val) {
            this.setStyles(val);
        }
    },
    mounted() {
        if (this.styling) {
            this.setStyles(this.styling);
        }

        this.$nextTick(() => {
          this.isLoaded = true;
        });

        this.setPercentageFillByDirection();
    },

    methods: {
        setPercentageFillByDirection(){
            switch (this.style.direction) {
                case "top-bottom":
                case "bottom-top":
                    this.style.width = 100;
                    this.style.height = this.progressValue;
                    break;
                case "left-right":
                case "right-left":
                    this.style.width = this.progressValue;
                    this.style.height = 100;
                    break;
            }
        },
        setStyles(styles) {
            var driverBgColour = getComputedStyle(
                document.getElementsByClassName("gf-driver-button")[0],
                null
            ).getPropertyValue("background-color");

            if (!styles.fillColour || styles.fillColour == "")
                this.style.fillColour = driverBgColour;
            else this.style.fillColour = styles.fillColour;
            
            if (styles.imageUrl)
                this.style.imageUrl = styles.imageUrl;

            if (styles.direction)
                this.style.direction = styles.direction;
                
            if (styles.fillMaxWidth)
                this.style.fillMaxWidth = styles.fillMaxWidth;                

            logger.logInfo("ImageFill.setStyles, styles: ", this.style);
        },
    }
};
</script>
<style scoped>
    .fundometer-fill-wrap{
        margin-left: auto;
        margin-right: auto;
    }

    .fundometer-fill {
        width: 100%;        
        margin: 0 auto;
        position: relative;
    }
	
    .fill-image img {
        width: 100%;
    }	

    .fill-image {        
        width: 100%;
        position: relative;
        z-index: 1;
    }
    
    .color-fill-container{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 100%;
    }

    .color-fill-container {
        height: 100%;
    }

    .color-fill-wrap {
        width: 100%;
        height: 100%;
        position: relative;
        margin: 0 auto;
    }

    .fill-progress {
        position: absolute;
        transition: all 3s;
    }

    .fill-progress.loaded {
        transition: all 3s;
    }

    /*-- COLOR FILL --*/    
    .color-fill {
        background: #009edb;
    }

    /*-- DIRECTION FILL --*/    
    .df-bottom-top {
        width: 100%;
        left: 0;
        bottom: 0;
        max-height: 0;
        height: 0;
        -webkit-transition: height 3s;  
        transition: height 3s;
    }

    .df-bottom-top.loaded {
        max-height: 100%;
        height: 50%;
        -webkit-transition: height 3s;  
        transition: height 3s;
    }

    .df-top-bottom {
        width: 100%;
        left: 0;
        top: 0;
        max-height: 0;
        height: 0;
        -webkit-transition: height 3s;
          transition: height 3s;
    }

    .df-top-bottom.loaded {
        max-height: 100%;
        height: 50%;
        -webkit-transition: height 3s;  
        transition: height 3s;
    }

    .df-left-right {
        width: 0;
        left: 0;
        bottom: 0;
        max-width: 0;
        height: 100%;
        -webkit-transition: width 3s;  
        transition: width 3s;
    }

    .df-left-right.loaded {
        max-width: 100%;
        width: 50%;
        -webkit-transition: width 3s; 
         transition: width 3s;
    }

    .df-right-left{
        width: 0;
        right: 0;
        bottom: 0;
        max-width: 0;
        height: 100%;
        -webkit-transition: width 3s;
        transition: width 3s;
    }

    .df-right-left.loaded {
        max-width: 100%;
        width: 50%;
        -webkit-transition: width 3s;
        transition: width 3s;
    }
</style>

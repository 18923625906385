
<script>
import ActivitySummary from "../ActivitySummary";
import informationDigestService from "../../common/InformationDigestService";

export default {
  name: "EventCampaignSummary",
  mixins: [ActivitySummary],

  methods: {
    async fetchFromServices() {     
      return await informationDigestService.getEventCampaignDigestSummary(
        this.eventCampaignId
      );
    }
  }
};
</script>

<script>
import GfInformationDigestBase from '../../GfInformationDigestBase.vue';
import { logger } from "@gf/gf-logger";

export default {
    components: {},
    
    mixins: [GfInformationDigestBase],

    data() {
        return {
            counter: {
                value: 0
            },
            logger: logger,
            title: ""  
        };
    },

    async mounted() {
        this.title = this.componentSettings.Title;
        this.$nextTick(async () => await this.$_CounterBase_AfterMounted())
        await this.$_CounterBase_GetCounterValue()
    },

    methods: {
        async $_CounterBase_AfterMounted() {
          // override
        },

        async $_CounterBase_GetCounterValue() {
          // override
        }
    }
};
</script>

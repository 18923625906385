import ActivityEntry from "./components/ActivityEntry/ActivityEntry";
import BlogContentEntry from "./components/Content/BlogContentEntry";
import BlogPostsFeed from "./components/BlogPostDigest/BlogPostsFeed"
import Fundometer from "./components/Fundometer/Fundometer";
import FundraisingPageFeed from "./components/FundraisingPageFeed.vue";
import FundraisingPageSummary from "./components/FundraisingPage/FundraisingPageSummary";
import EventCampaignSummary from "./components/FundraisingPage/EventCampaignSummary";
import EventCampaignPaymentCounter from "./components/Counters/EventCampaignPaymentCounter";
import FundraisingPagePaymentFeed from "./components/FundraisingPage/FundraisingPagePaymentFeed";
import PaymentTicker from "./components/Tickers/PaymentTicker";
import SupportersFeed from "./components/SupportersFeed.vue";
import FundraisingPageTopDonations from "./components/FundraisingPage/FundraisingPageTopDonations";

var AvailableComponents = [
        {
            name: 'activityEntry',
            component: ActivityEntry
        },
        {
            name: 'fundometer',
            component: Fundometer
        },
        {
            name: 'fundraisingPageFeed',
            component: FundraisingPageFeed
        },
        {
            name: 'fundraisingPageSummary',
            component: FundraisingPageSummary
        },
        {
            name: 'eventCampaignSummary',
            component: EventCampaignSummary
        },
        {
            name: 'supportersFeed',
            component: SupportersFeed
        },
        {
            name: 'fundraisingPagePaymentFeed',
            component: FundraisingPagePaymentFeed
        },
        {
            name: 'blogContentEntry',
            component: BlogContentEntry
        },
        {
            name: 'blogPostsFeed',
            component: BlogPostsFeed
        },
        {
            name: 'eventCampaignPaymentCounter',
            component: EventCampaignPaymentCounter
        },
        {
            name: 'paymentTicker',
            component: PaymentTicker
        },
        {
            name: 'fundraisingPageTopDonations',
            component: FundraisingPageTopDonations
        }
    ]

export default {
    LoadComponent: function(name) {
        return AvailableComponents.find(el => el.name.toLowerCase() === name.toLowerCase()).component;
    }
}

<template lang="html">
  <div
    v-if="showActvity"
    class="gf-ad col mb-4"
  >
    <div class="row pt-2">
      <div class="col-2 col-sm-1">
        <div class="row">
          <div class="m-auto justify-content-md-center">
            <ProfileImage
              :image-src="digest.Creator.ProfileImage"
              :gender="digest.Creator.AdditionalProperties.Gender"
              :display-name="digest.Creator.DisplayName"
              :size="40"
            />
          </div>
        </div>
      </div>
      <div class="col-10 col-sm-11 px-0">
        <div class="row">
          <div class="col-8">
            <div class="col h5">
              {{ digest.Creator.DisplayName }}
            </div>
            <div class="col h5 font-weight-bold">
              {{ formattedDate }}
            </div>
            <div class="col">
              <img
                class="gf-ad-time"
                src="@/assets/images/icon-time.png"
              >
              <span class="pl-2">{{ formattedTime }}</span>
            </div>
          </div>
          <div class="col-4">
            <button
              v-if="(typeof digest.Id != 'undefined' && digest.Id && isPageOwner)"
              type="button"
              class="btn btn-outline-secondary"
              value="Delete"
              @click="onItemDeleted()"
            >
              <font-awesome-icon :icon="trashAlt" /> Delete
            </button>
            <div class="gf-ad-sysimg float-right">
              <img
                v-if="digest.AdditionalProperties
                  .ActivityTrackerSystemImage && digest.AdditionalProperties
                    .ActivityTrackerSystemImage.length > 0"
                class="col"
                :src="
                  digest.AdditionalProperties
                    .ActivityTrackerSystemImage
                "
                :alt="
                  digest.AdditionalProperties
                    .ActivityTrackerSystemName
                "
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        v-if="digest.SpatialData != '' && state.gmap "
        class="col px-0"
      >
        <Map :geo-json="JSON.parse(digest.SpatialData)" />
      </div>
    </div>
    <div class="row">
      <div class="gf-ad-counters col p-2 mt-3">
        <ActivityDigestCounters
          :digest="digest"
          @hasValues="(val) => showActvity = val"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../common/store";
import logger from "../../common/logger";
import moment from "moment";
import ActivityDigestCounters from "./ActivityDigestCounters";
import Map from "./Map";
import ProfileImage from "../Common/ProfileImage";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "ActivityDigest",
  components: { ActivityDigestCounters, Map, ProfileImage, FontAwesomeIcon },

  props: {
    digest: {
      Type: Object,
      default: () => {}
    },
    isPageOwner: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      state: store.state,
      showActvity: true,
      trashAlt: faTrashAlt
    };
  },

  computed: {
    formattedDate() {
      return moment(this.digest.CreatedDateTimeOffset).format(
        "ddd Do MMMM YYYY"
      );
    },

    formattedTime() {
      return moment(this.digest.CreatedDateTimeOffset).format("h:mm a");
    }

  },
  methods: {
    onItemDeleted() {
        this.$emit('onItemDeleted')
    }
  }

};
</script>

<style scoped>
.gf-ad {
  background: #f8f8f8;
}

.gf-ad-time {
  width: 13px;
}

.gf-ad-counters {
  background: #f3f3f3;
}

.gf-ad-sysimg {
  max-width: 100px;
}
</style>
